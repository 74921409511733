import { DatePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ProgressIndicatorComponent } from "@app/common/progress-indicator/progress-indicator.component";
import { ConfirmAlertComponent } from "@app/components/confirm-alert/confirm-alert.component";
import { CcModalRef, CC_MODAL_DATA } from "@app/components/modal";
import { EstimatePricingService } from "@app/services/estimate-pricing/estimate-pricing.service";
import { UserService } from "@app/services/misc/user.service";
import { Pricetype } from "@app/services/models/pricetype";
import { DataService } from "@app/services/shared/storage/data.service";

@Component({
  selector: "app-view-report-options",
  templateUrl: "./view-report-options.component.html",
  styleUrls: ["./view-report-options.component.scss"],
})
export class ViewReportOptionsComponent implements OnInit, OnDestroy {
  @Input() tableList = [];
  groupingData: any;
  @ViewChild("htmlData", { static: true }) public htmlData: ElementRef;
  realTimeFilterChanged: Subject<any> = new Subject();
  freqValueChanged: Subject<any> = new Subject();
  columnFilters: any;
  isShowReport: boolean;
  isShowReportImage: boolean;
  reportImagePath: string;
  reportType: any;
  reportList = [];
  listSupport = [];
  reportCurrencytype = [];
  /*name of the excel-file which will be downloaded. */
  fileName = "ExcelSheet.xlsx";
  rowNumbers: any;
  columnNumbers: any;
  currentRow: any;
  isCostbyinterval: any;
  isAnalysis: any;
  isPartsListReport: any;
  isPartsConsumedbyIntervalReport: any;
  isFilterEmpty: any;
  isPartsConsumed: any;
  isRepairJobDetails: any;
  isPartsListForServiceOptions: any;
  isIncludezeroreplacement: any;
  isEscaltedpartsinclude: any = false;
  isVariabilityFactorsinclude: any = false;
  isMiscPartsinclude: any = false;
  isIncludeZeroUnitPrice: any;
  isIncludeZeroQuantity: any;
  isIncludeCoreCredit: boolean;
  ShowConvertedCurrencyTotals: any;
  isParts: any;
  // preview report
  previewReport: any;
  previewReportHeader = [];
  smcs: string;
  interval: string;
  eventLabHrs: string;
  downtime: string;
  previewReportComponent = [];
  previewReportComponentList = [];
  previewReportEngine = [];
  previewReportEngineList = [];
  previewMaintenance = [];
  previewTotal = [];
  previewReportRateHour = [];
  previewReportVerticalHeader = [];
  showTable: any;
  selectedFilter = [];
  selectedPrice = [];
  priceTypeArray: Array<Pricetype> = [];
  // //has to be get from initial API pricetype & filterList
  priceType = [];
  contentType = [];
  filterList = [];
  // dummy array for categorization -> need to remove
  filterList2 = [];
  filterList3 = [];
  filterList4 = [];
  filterList5 = [];
  jobCorrelationIds = [];
  // end
  planNumber = "";
  myTemplate: any;
  adv = true;
  frequency = 1000;
  // static values for categorization price logic ->need to remove
  totalprice = true;
  perhourprice = true;
  filterJson: any;
  ShowCustomer = true;
  customerList = [{ Customer: true }];
  updatedFilterOptions: any;
  previousPrice = [];
  filterChanged = false;
  showRepair: boolean;
  frequencyError = false;
  reportForm: FormGroup;
  public fomFilterCtrl: FormControl = new FormControl();
  estimateName: any;
  prefernceData = {
    Name: "",
    Settings: [],
  };
  includeCategoryOptions = [
    { DisplayName: "IncludeZeroReplacement", isChecked: true },
    { DisplayName: "IncludeZeroUnitPrice", isChecked: true },
    { DisplayName: "IncludeZeroQuantity", isChecked: true },
    { DisplayName: "IncludeCoreCredit", isChecked: true },
  ];

  disableitemforpartsInterval = [
    "Qty",
    "Loc",
    "App",
    "Cond",
    "Cab",
    "First",
    "Frequency",
  ];
  prefernceName: any;
  custSelected;
  freqValue = 1000;
  isPreferenced;
  UtilizationRate: any;
  IsSourceCurrencyTotalsAvailable: any;
  disablefrequency: any = false;
  reportCurrencyMode: any;
  configuredCurrency: any;
  estimatCurrency: any;
  disclaimerReports = ["RepairOptionDetails", "PartsConsumedPerServiceOption"];
  isEscalatedPricesAvailable: any;
  isVariabilityAvailable: any;
  isMiscPartsAvailable = false;
  showDisclaimer: boolean;
  warningMessage: boolean;
  dividedValue: number;
  duration: any;
  popupflag = false;
  threshouldColumnCount: any = 90;
  previewUnsubscribe: Subscription;
  isRepairOptionsList: any;
  isMachineOrUnderCarriageVarAvailable: boolean = false;
  isRIVaraibilityAvailable: boolean = false;
  constructor(
    public fb: FormBuilder,
    public dialogRef: CcModalRef<ViewReportOptionsComponent>,
    @Inject(CC_MODAL_DATA) public data: any,
    private estimateService: EstimatePricingService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private dataService: DataService,
    private userService: UserService,
    public snackBar: MatSnackBar
  ) {
    this.currentRow = data.planNumber;
    this.estimateName = data.estimateName;
    this.duration = data.duration;
    // use dynamic planNumber
    this.planNumber = this.currentRow;
    this.isShowReport = false;
    this.isShowReportImage = false;
    this.reportImagePath = "/assets/images/repair-event-detail-report.png";
  }
  ngOnInit() {
    this.estimateService.accordionData.subscribe(res => {
      this.groupingData = res;
    });
    this.rowNumbers = Array(500).fill(4);
    this.columnNumbers = Array(5).fill(4);
    this.getInitialList();
    const element4 = document.getElementById("bodyscroll");
    element4.style.overflow = "hidden";
    this.freqValueChanged.pipe(debounceTime(1000)).subscribe((data: any) => {
      if (data > this.threshouldColumnCount) {
        this.warningMessage = true;
        this.cdr.detectChanges();
      } else {
        this.warningMessage = false;
      }
    });
    this.realTimeFilterChanged
      .pipe(debounceTime(2000))
      .subscribe((data: any) => {
        if (this.prefernceData.Name && !this.isShowReport) {
          this.userService
            .saveUserPreferences(this.prefernceData)
            .subscribe(() => {
              const data = this.dataService.userPrefenceData;
              const userData = data.filter(
                (elem) => elem.Name == this.prefernceData.Name
              );
              if (userData.length != 0) {
                const index = this.dataService.userPrefenceData.indexOf(
                  userData[0]
                );
                this.dataService.userPrefenceData.splice(index, 1);
              }
              this.dataService.userPrefenceData.push({
                Name: this.prefernceData.Name,
                Settings: this.prefernceData.Settings,
              });
            });
        }
      });

    if (this.disclaimerReports.includes(this.reportType)) {
      this.showDisclaimer = true;
    } else {
      this.showDisclaimer = false;
    }
    this.estimatCurrency = this.translate.instant(
      "reportFilters.0." + "estimateCurrency"
    );
    this.configuredCurrency = this.translate.instant(
      "reportFilters.0." + "configuredCurrency"
    );
  }

  ngOnDestroy() {
    const element4 = document.getElementById("bodyscroll");
    element4.style.overflow = "unset";
  }
  getInitialList() {
    // get initial filter and other details
    // has to be get from initial API
    this.reportForm = this.fb.group({
      frequency: [null, [Validators.required]],
    });
    // loader
    const modalRef1 = this.modalService.open(ProgressIndicatorComponent, {
      centered: true,
      backdrop: "static",
      backdropClass: "edit-estimate-spinner",
      windowClass: "progress-spinner-modal progress-spinner-edit-estimate",
      keyboard: false,
    });
    this.isMiscPartsAvailable = false;
    this.updateFormData();
    this.filterList = [];
    this.selectedFilter = [];
    this.priceType = [];
    this.selectedPrice = [];
    // need to bind details from API
    // default priceType // need to fetch from API res
    this.cdr.detectChanges();
    // dynamic API call()
    this.estimateService.getInitialReportDetails(this.planNumber).subscribe(
      (res) => {
        // need to bind details from API
        if (res.IsSuccess) {
          modalRef1.close();
          if (res.Filters !== null) {
            this.listSupport = res.Filters;
            this.listSupport.forEach((list) => {
              this.reportList.push(list.ReportType);
              this.IsSourceCurrencyTotalsAvailable =
                list.IsSourceCurrencyTotalsAvailable;
              this.isEscalatedPricesAvailable = list.IsEscalatedPricesAvailable;
              this.isMachineOrUnderCarriageVarAvailable = list.IsVariabilityFactorsAvailable;
              this.isRIVaraibilityAvailable = list.IsApplicationVariabilityFactorsAvailable;
              let isAnyVariabilityAvailable = (this.isMachineOrUnderCarriageVarAvailable || this.isRIVaraibilityAvailable) ? true : false;
              this.isVariabilityAvailable = isAnyVariabilityAvailable;
              this.isMiscPartsinclude = list.IsMiscellaneousPriceAvailable;
              if (this.isEscalatedPricesAvailable) {
                this.isEscaltedpartsinclude = true;
              } else {
                this.isEscaltedpartsinclude = false;
              }
              if (this.isVariabilityAvailable) {
                this.isVariabilityFactorsinclude = true;
              } else {
                this.isVariabilityFactorsinclude = false;
              }

              if (list.ReportType == "PartsConsumedByInterval") {
                this.UtilizationRate = list.UtilizationRate;
              }
            });

            // default one report type
            this.reportType = this.reportList[0];

            // get content type and filterOptions  based on report type
            this.listSupport.forEach((list) => {
              this.handleListSupport(list);
            });

            // default price type
            this.selectedPrice = this.priceType.filter((list) => {
              return list.toLowerCase() == "sell";
            });

            // copy of filterOptions res to handle preview and download api
            this.selectedFilter = JSON.parse(JSON.stringify(this.filterJson));
            // add is checked for handling UI filteroptions
            this.filterJson.filter((category) => {
              this.handleCategory(category);
            });
            this.getPrefernces(this.filterJson);
            // setting current filterOption and PriceType
            this.updatedFilterOptions = JSON.parse(
              JSON.stringify(this.filterJson)
            );
            this.previousPrice = JSON.parse(JSON.stringify(this.selectedPrice));
            this.cdr.detectChanges();
          } else {
            this.handleErrorMessage(res);
          }
        } else {
          this.showErrMsg();
        }
      },
      (_err) => {
        modalRef1.close();
        this.showErrMsg();
      }
    );
  }

  handleCategory(category) {
    category.ColumnElements.filter((list) => {
      list.isChecked = true;
      list.disabled = false;
    });
  }
  handleCategoryPartsInterval(category) {
    category.ColumnElements.filter((list) => {
      if (this.disableitemforpartsInterval.includes(list.DisplayName)) {
        list.isChecked = false;
      } else {
        list.isChecked = true;
      }

      list.disabled = false;
    });
  }

  handleListSupport(list) {
    if (list.ReportType == this.reportType) {
      this.contentType = list.ContentType;
      this.filterJson = list.FilterOptions;
      this.columnFilters = this.filterJson[0];
      this.priceType = list.PriceType;
      this.IsSourceCurrencyTotalsAvailable =
        list.IsSourceCurrencyTotalsAvailable;
      this.isEscalatedPricesAvailable = list.IsEscalatedPricesAvailable;
      let isAnyVariabilityAvailable = (list.IsVariabilityFactorsAvailable || list.IsApplicationVariabilityFactorsAvailable) ? true : false;
      this.isVariabilityAvailable = isAnyVariabilityAvailable;
      this.isMiscPartsinclude = list.IsMiscellaneousPriceAvailable;
      if (this.isEscalatedPricesAvailable) {
        this.isEscaltedpartsinclude = true;
      } else {
        this.isEscaltedpartsinclude = false;
      }
      if (this.isVariabilityAvailable) {
        this.isVariabilityFactorsinclude = true;
      } else {
        this.isVariabilityFactorsinclude = false;
      }
      if (this.IsSourceCurrencyTotalsAvailable) {
        this.reportCurrencytype = [];
        this.reportCurrencytype.push(this.estimatCurrency);
        this.reportCurrencytype.push(this.configuredCurrency);
      } else {
        this.reportCurrencytype = [];
        this.reportCurrencytype.push(this.estimatCurrency);
      }
      this.reportCurrencyMode = this.reportCurrencytype[0];
    }
  }

  updateFormData() {
    // setting a default value
    this.reportForm.controls.frequency.setValue(1000);

    this.frequencyError = false;
    this.frequency = 1000;
    if (
      this.reportType === "PartsConsumedByInterval" &&
      this.UtilizationRate &&
      this.UtilizationRate > 0
    ) {
      this.frequency = this.UtilizationRate;
      this.reportForm.controls.frequency.setValue(this.frequency);
      this.disablefrequency = true;
    } else {
      this.disablefrequency = false;
    }
  }
  onClose() {
    if (this.isShowReport) {
      this.myTemplate = null;
      if (this.previewUnsubscribe) {
        this.previewUnsubscribe.unsubscribe();
      }
      this.gotointialView();
    } else {
      this.dialogRef.close({});
    }
  }
  gotointialView() {
    this.isShowReport = false;
    this.displayAdvancefilter();
  }
  reportSelect(event) {
    this.isPreferenced = false;
    this.prefernceData.Name = "";
    this.selectedPrice = [];
    this.customerList = [{ Customer: true }];
    this.reportType = event.value;
    this.warningMessage = false;
    if (this.disclaimerReports.includes(this.reportType)) {
      this.showDisclaimer = true;
    } else {
      this.showDisclaimer = false;
    }
    this.custSelected = true;
    this.freqValue = 1000;
    this.reportTypeCall();
    // need to revisit
    this.reportTypeCall1();
    // get content type and filterOptions  based on report type
    this.listSupport.forEach((list) => {
      this.handleListSupport(list);
    });
    this.PriceCall();

    // copy of filterOptions res to handle preview and download api
    this.selectedFilter = JSON.parse(JSON.stringify(this.filterJson));
    // add is checked for handling UI filteroptions
    if (this.filterJson != null) {
      this.filterJson.filter((category) => {
        if (this.isPartsConsumedbyIntervalReport) {
          this.handleCategoryPartsInterval(category);
        } else {
          this.handleCategory(category);
        }
      });
      this.cdr.detectChanges();
    }
    this.updateFormData();
    this.getPrefernces(this.filterJson);
  }
  showReport() {
    // get report preview based on the report Type - we have to pass column and report type
    if (this.adv && !this.isShowReport) {
      this.adv = false;
    }
    this.isShowReport = true;
    this.filterChanged = false;
    this.getReportPreview();
  }
  frequencyChange(event) {
    const val = event.value;
    this.warningMessage = false;
    if (val === "") {
      this.frequencyError = true;
      this.filterChanged = false;
    } else {
      this.filterChanged = true;
      this.frequencyError = false;
      this.freqValue = val;
      this.checkWarningMessage();
    }
  }
  updatePreview() {
    this.filterChanged = false;
    this.showReport();
    this.displayAdvancefilter();
  }
  // passing  updated FilterOption to API call
  getUpdatedFilter() {
    const updatedFilter = [];
    if (this.selectedFilter != null) {
      this.selectedFilter.forEach((category) => {
        let newColumnList = [];
        newColumnList = category.ColumnElements.filter((list) => {
          if (list.isChecked) {
            delete list.isChecked;
            return list;
          }
        });
        category.ColumnElements = newColumnList;
      });

      this.selectedFilter.forEach((category) => {
        if (category.ColumnElements.length > 0) {
          updatedFilter.push(category);
        }
      });
    }
    return updatedFilter;
  }
  getReportPreview() {
    // get updated filterOptions
    this.ShowConvertedCurrencyTotals = this.getCurrencySourceType();
    const reportFormValue = this.reportForm.value;
    this.frequency = reportFormValue.frequency;
    this.selectedFilter = JSON.parse(JSON.stringify(this.filterJson));
    this.updatedFilterOptions = JSON.parse(JSON.stringify(this.filterJson));
    this.previousPrice = JSON.parse(JSON.stringify(this.selectedPrice));
    let updatedFilter = [];
    updatedFilter = this.getUpdatedFilter();
    let isRIVaraibilityAvailableAndIncluded = (this.isVariabilityFactorsinclude && this.isRIVaraibilityAvailable) ? true : false;
    let isMachineOrUnderCarriageVarAvailableAndIncluded = (this.isVariabilityFactorsinclude && this.isMachineOrUnderCarriageVarAvailable) ? true : false;
    // pass filter values and reportType
    const data = {
      Frequency: this.frequency,
      Report: this.reportType,
      ContentTypes: [this.contentType[0]],
      FilterOptions: updatedFilter,
      PriceTypes: this.selectedPrice,
      IncludeApplicationVariabilityFactors: isRIVaraibilityAvailableAndIncluded,
      IncludeZeroReplacement: this.isIncludezeroreplacement,
      IncludeZeroUnitPrice: this.isIncludeZeroUnitPrice,
      IncludeZeroQuantity: this.isIncludeZeroQuantity,
      IncludeCoreCredit: this.isIncludeCoreCredit,
      IncludeEscalatedPrices: this.isEscaltedpartsinclude,
      IncludeVariabilityFactors: isMachineOrUnderCarriageVarAvailableAndIncluded,
      IncludeMiscellaneousPrice: this.isMiscPartsinclude,
      ShowCustomer: this.ShowCustomer,
      ShowConvertedCurrencyTotals: this.ShowConvertedCurrencyTotals,
      LanguageCode: localStorage.getItem("locale"),
    };
    // customer needed or not static value update
    this.customerList[0].Customer = this.ShowCustomer;

    this.showTable = false;
    // loader
    const modalRef1 = this.modalService.open(ProgressIndicatorComponent, {
      centered: true,
      backdrop: "static",
      backdropClass: "edit-estimate-spinner",
      windowClass: "progress-spinner-modal progress-spinner-edit-estimate",
      keyboard: false,
    });
    modalRef1.componentInstance.message = this.translate.instant(
      "lbl-report-preview-msg"
    );
    // end
    this.previewUnsubscribe = this.estimateService
      .getPreviewReport(this.planNumber, data)
      .subscribe(
        (response) => {
          if (response.IsSuccess) {
            if (response.ReportContentView.Content) {
              const tableHtml = response.ReportContentView.Content;
              this.myTemplate =
                this.sanitizer.bypassSecurityTrustHtml(tableHtml);
              this.showTable = true;
            } else {
              if (response.FeedBackMessage1) {
                this.errorPopup(response.FeedBackMessage);
              }
            }
            this.cdr.detectChanges();
            modalRef1.close();
          } else {
            this.handleErrorMessage(response);
          }
        },
        (err) => {
          modalRef1.close();
          this.showTable = true;
          this.isShowReport = false;
          this.popupflag = true;
          this.showErrMsg();
          this.cdr.detectChanges();
        }
      );
  }

  openPDF() {
    // loader
    const modalRef1 = this.modalService.open(ProgressIndicatorComponent, {
      centered: true,
      backdrop: "static",
      backdropClass: "edit-estimate-spinner",
      windowClass: "progress-spinner-modal progress-spinner-edit-estimate",
      keyboard: false,
    });
    // get updated filterOptions
    this.ShowConvertedCurrencyTotals = this.getCurrencySourceType();
    const reportFormValue = this.reportForm.value;
    this.frequency = reportFormValue.frequency;
    this.selectedFilter = JSON.parse(JSON.stringify(this.filterJson));
    let updatedFilter = [];
    updatedFilter = this.getUpdatedFilter();
    let isRIVaraibilityAvailableAndIncluded = (this.isVariabilityFactorsinclude && this.isRIVaraibilityAvailable) ? true : false;
    let isMachineOrUnderCarriageVarAvailableAndIncluded = (this.isVariabilityFactorsinclude && this.isMachineOrUnderCarriageVarAvailable) ? true : false;
    // pass filter values and reportType
    const data = {
      Frequency: this.frequency,
      Report: this.reportType,
      ContentTypes: [this.contentType[2]],
      FilterOptions: updatedFilter,
      PriceTypes: this.selectedPrice,
      ShowCustomer: this.ShowCustomer,
      IncludeApplicationVariabilityFactors: isRIVaraibilityAvailableAndIncluded,
      IncludeEscalatedPrices: this.isEscaltedpartsinclude,
      IncludeMiscellaneousPrice: this.isMiscPartsinclude,
      IncludeVariabilityFactors: isMachineOrUnderCarriageVarAvailableAndIncluded,
      ShowConvertedCurrencyTotals: this.ShowConvertedCurrencyTotals,
      LanguageCode: localStorage.getItem("locale"),
    };
    // customer needed or not static value update
    this.customerList[0].Customer = this.ShowCustomer;
    this.estimateService
      .getUserSpecifiedReport(this.planNumber, data, "pdf")
      .subscribe(
        (response) => {
          modalRef1.close();
          if (response.headers.get("content-type").includes("text/plain")) {
            const b = new Blob([response.body], {
              type: "text/plain",
            });
            const fr = new FileReader();

            fr.onload = (e: any) => {
              const jsonForError = e.target.result;
              this.errorPopup(jsonForError);
            };

            fr.readAsText(b);
          } else {
            const file = new Blob([response.body], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(file);
            link.download = this.getReportFilname() + ".pdf";
            link.click();
            link.remove();
          }
        },
        (_err) => {
          modalRef1.close();
          this.showErrMsg();
        }
      );
  }

  exportexcel() {
    const modalRef1 = this.modalService.open(ProgressIndicatorComponent, {
      centered: true,
      backdrop: "static",
      backdropClass: "edit-estimate-spinner",
      windowClass: "progress-spinner-modal progress-spinner-edit-estimate",
      keyboard: false,
    });
    // get updated filterOptions
    this.ShowConvertedCurrencyTotals = this.getCurrencySourceType();
    const reportFormValue = this.reportForm.value;
    this.frequency = reportFormValue.frequency;
    this.selectedFilter = JSON.parse(JSON.stringify(this.filterJson));
    let updatedFilter = [];
    updatedFilter = this.getUpdatedFilter();
    // updated filteroption after update
    this.updatedFilterOptions = this.filterJson;
    let isRIVaraibilityAvailableAndIncluded = (this.isVariabilityFactorsinclude && this.isRIVaraibilityAvailable) ? true : false;
    let isMachineOrUnderCarriageVarAvailableAndIncluded = (this.isVariabilityFactorsinclude && this.isMachineOrUnderCarriageVarAvailable) ? true : false;
    // pass filter values and reportType
    const data = {
      Frequency: this.frequency,
      Report: this.reportType,
      ContentTypes: this.getContentType(),
      FilterOptions: updatedFilter,
      PriceTypes: this.selectedPrice,
      ShowCustomer: this.ShowCustomer,
      IncludeApplicationVariabilityFactors: isRIVaraibilityAvailableAndIncluded,
      IncludeZeroReplacement: this.isIncludezeroreplacement,
      IncludeZeroUnitPrice: this.isIncludeZeroUnitPrice,
      IncludeZeroQuantity: this.isIncludeZeroQuantity,
      IncludeCoreCredit: this.isIncludeCoreCredit,
      IncludeEscalatedPrices: this.isEscaltedpartsinclude,
      IncludeVariabilityFactors: isMachineOrUnderCarriageVarAvailableAndIncluded,
      IncludeMiscellaneousPrice: this.isMiscPartsinclude,
      ShowConvertedCurrencyTotals: this.ShowConvertedCurrencyTotals,
      LanguageCode: localStorage.getItem("locale"),
    };
    // customer needed or not static value update
    this.customerList[0].Customer = this.ShowCustomer;
    this.estimateService
      .getUserSpecifiedReport(this.planNumber, data, "excel")
      .subscribe(
        (response) => {
          modalRef1.close();
          if (response.headers.get("content-type").includes("text/plain")) {
            const b = new Blob([response.body], {
              type: "text/plain",
            });
            const fr = new FileReader();

            fr.onload = (e: any) => {
              const jsonForError = e.target.result;
              this.errorPopup(jsonForError);
            };

            fr.readAsText(b);
          } else {
            const file = new Blob([response.body], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            this.downloadFile(file, this.getReportFilname() + ".xlsx");
          }
        },
        (_err) => {
          modalRef1.close();
          this.showErrMsg();
        }
      );
  }
  exportCSV() {
    // loader
    this.groupingData?.forEach(item => {
      if(item?.Configuration?.IsActive && item?.ServiceIntervals?.length > 0){
        item.ServiceIntervals.forEach(interval => {
          if(interval?.Configuration?.Header?.IsActive){
            interval?.Configuration?.Details?.Jobs.forEach(job => {
              if(job?.IsActive){
                this.jobCorrelationIds.push(job.CorrelationId);
              }
            });
          }
        });
      }
    });
    const modalRef1 = this.modalService.open(ProgressIndicatorComponent, {
      centered: true,
      backdrop: "static",
      backdropClass: "edit-estimate-spinner",
      windowClass: "progress-spinner-modal progress-spinner-edit-estimate",
      keyboard: false,
    });
    // get updated filterOptions
    this.ShowConvertedCurrencyTotals = this.getCurrencySourceType();
    const reportFormValue = this.reportForm.value;
    this.frequency = reportFormValue.frequency;
    this.selectedFilter = JSON.parse(JSON.stringify(this.filterJson));
    let updatedFilter = [];
    updatedFilter = this.getUpdatedFilter();
    // updated filteroption after update
    this.updatedFilterOptions = this.filterJson;
    let isRIVaraibilityAvailableAndIncluded = (this.isVariabilityFactorsinclude && this.isRIVaraibilityAvailable) ? true : false;
    // pass filter values and reportType
    const data1 = {
      Report: this.reportType,
      ContentTypes: [this.contentType[0]],
      JobCorrelationIds: this.jobCorrelationIds,
      FilterOptions: updatedFilter,
      PriceTypes: [],
      LanguageCode: localStorage.getItem("locale"),
    };
    const data2 = {
      Frequency: this.frequency,
      Report: this.reportType,
      ContentTypes: [this.contentType[1]],
      FilterOptions: updatedFilter,
      PriceTypes: this.selectedPrice,
      ShowCustomer: this.ShowCustomer,
      IncludeApplicationVariabilityFactors: isRIVaraibilityAvailableAndIncluded,
      IncludeZeroReplacement: this.isIncludezeroreplacement,
      IncludeEscalatedPrices: this.isEscaltedpartsinclude,
      IncludeVariabilityFactors: this.isVariabilityFactorsinclude,
      IncludeMiscellaneousPrice: this.isMiscPartsinclude,
      ShowConvertedCurrencyTotals: this.ShowConvertedCurrencyTotals,
      LanguageCode: localStorage.getItem("locale"),
    };
    // customer needed or not static value update
    this.customerList[0].Customer = this.ShowCustomer;
    this.estimateService
      .getUserSpecifiedReport(this.planNumber, this.isPartsListForServiceOptions ? data1 : data2, "text/csv")
      .subscribe(
        (response) => {
          modalRef1.close();
          if (response.headers.get("content-type").includes("text/plain")) {
            const b = new Blob([response.body], {
              type: "text/plain",
            });
            const fr = new FileReader();
            fr.onload = (e: any) => {
              const jsonForError = e.target.result;
              this.errorPopup(jsonForError);
            };
            fr.readAsText(b);
          } else {
            const file = new Blob([response.body], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            this.downloadFile(file, this.getReportFilname() + ".csv");
          }
        },
        (_err) => {
          modalRef1.close();
          this.showErrMsg();
        }
      );
  }

  // get selected price
  onPriceChanged(event) {
    if (this.isCostbyinterval && !this.reportForm.valid) {
      this.filterChanged = false;
    } else {
      this.filterChanged = true;
    }
    this.selectedPrice = [];
    if (!event.target.checked) {
      const updatedFilter = [];
      this.priceType.forEach((list) => {
        if (list !== event.target.value) {
          updatedFilter.push(list);
        }
      });
      this.selectedPrice = updatedFilter;
    } else {
      this.selectedPrice.push(event.target.value);
    }
  }
  onPriceChangedAnalysis(event, value, valueArray) {
    if (!this.reportForm.valid) {
      this.filterChanged = false;
    } else {
      this.filterChanged = true;
    }
    let count = 0;
    let tempCol;
    if (!event) {
      this.priceTypeArray.forEach((elmt) => {
        if (elmt.isChecked) {
          tempCol = elmt;
          count++;
        }
      });
      if (count === 1) {
        tempCol.disabled = true;
      }
    } else {
      this.priceTypeArray.forEach((list) => {
        list.disabled = false;
      });
    }
    this.selectedPrice = [];
    this.priceTypeArray.forEach((list) => {
      if (list.isChecked) {
        this.selectedPrice.push(list.name);
      }
    });
  }
  displayAdvancefilter() {
    this.adv = !this.adv;
    this.reportForm.controls.frequency.setValue(this.frequency);
    this.frequencyError = false;
    this.reportForm.controls.frequency.updateValueAndValidity();
  }

  cancelFilterChange() {
    this.adv = !this.adv;
    this.filterChanged = false;
    this.filterJson = JSON.parse(JSON.stringify(this.updatedFilterOptions));
    this.selectedPrice = JSON.parse(JSON.stringify(this.previousPrice));
    if (
      this.isAnalysis ||
      this.isPartsConsumed ||
      this.isPartsListReport ||
      this.isPartsConsumedbyIntervalReport
    ) {
      let checkedCount = 0;
      let temCol;
      this.priceTypeArray.forEach((elem) => {
        if (this.selectedPrice.includes(elem.name)) {
          elem.isChecked = true;
          temCol = elem;
          checkedCount++;
        } else {
          elem.isChecked = false;
        }
      });
      if (checkedCount == 1) {
        temCol.disabled = true;
      } else {
        this.priceTypeArray.forEach((elem) => {
          elem.disabled = false;
        });
      }
    }
  }
  closefilter() {
    this.adv = !this.adv;
  }
  onCategoryFilterChanged(event, categoryItem, category) {
    if (this.isCostbyinterval && !this.reportForm.valid) {
      this.filterChanged = false;
    } else {
      this.filterChanged = true;
    }

    if (event.currentTarget.checked === false) {
      // UI integration
      this.currentTargetCall(event, categoryItem);
    } else {
      // UI integration
      this.categoryFilterCall(event, categoryItem);
    }
    if (this.isAnalysis) {
      this.validateCategoryforAnalysis(event);
    }
    // end
  }
  validateCategoryforAnalysis(event) {
    let count = 0;
    let tempCol;
    if (event.currentTarget.checked === false) {
      this.filterJson.filter((category) => {
        category.ColumnElements.filter((list) => {
          if (list.isChecked) {
            tempCol = list;
            count++;
          }
        });
      });
      if (count === 1) {
        tempCol.disabled = true;
      }
    } else {
      this.filterJson.filter((category) => {
        category.ColumnElements.filter((list) => {
          list.disabled = false;
        });
      });
    }
  }

  // customerSelect
  customerSelected(event) {
    this.ShowCustomer = event.target.checked;
    this.custSelected = event.target.checked;
    if (this.isCostbyinterval && !this.reportForm.valid) {
      this.filterChanged = false;
    } else {
      this.filterChanged = true;
    }
  }
  includePercentageSelected(event) {
    this.isIncludezeroreplacement = event.target.checked;
    if (this.isCostbyinterval && !this.reportForm.valid) {
      this.filterChanged = false;
    } else {
      this.filterChanged = true;
    }
  }
  includeEscalatedpriceSelected(event) {
    this.isEscaltedpartsinclude = event.target.checked;
    if (!this.reportForm.valid) {
      this.filterChanged = false;
    } else {
      this.filterChanged = true;
    }
  }
  includeVariabilitySelected(event) {
    this.isVariabilityFactorsinclude = event.target.checked;
    if (!this.reportForm.valid) {
      this.filterChanged = false;
    } else {
      this.filterChanged = true;
    }
  }
  includecategorySelected(event, displayName) {
    if (this.isPartsConsumedbyIntervalReport && !this.reportForm.valid) {
      this.filterChanged = false;
    } else {
      this.filterChanged = true;
    }
    if (displayName === "IncludeZeroReplacement") {
      this.isIncludezeroreplacement = event.target.checked;
    } else if (displayName === "IncludeZeroUnitPrice") {
      this.isIncludeZeroUnitPrice = event.target.checked;
    } else if (displayName === "IncludeZeroQuantity") {
      this.isIncludeZeroQuantity = event.target.checked;
    } else if (displayName === "IncludeCoreCredit") {
      this.isIncludeCoreCredit = event.target.checked;
      this.includeCategoryOptions[3].isChecked = this.isIncludeCoreCredit;
    }
  }
  getReportFilname() {
    const today = new Date();

    let formattoday = "";
    formattoday = this.datePipe.transform(today, "dd-MMM-yyyy hh:mm a");
    return this.reportType + "_" + this.estimateName + "_" + formattoday;
  }

  getPrefernces(filterJson) {
    this.prefernceName = this.reportType;
    const data = this.dataService.userPrefenceData;
    const userData = data.filter((elem) => elem.Name == this.prefernceName);
    if (userData.length == 0) {
      this.userService
        .getUserPreferences(this.prefernceName)
        .subscribe((response) => {
          this.dataService.userPrefenceData.push({
            Name: this.prefernceName,
            Settings: response.Settings,
          });
          this.setPreferenceData(response, filterJson);
        });
    } else {
      this.setPreferenceData(userData[0], filterJson);
    }
  }

  setPreferenceData(response, filterJson) {
    const arr = [];
    for (const keys in response.Settings) {
      const val = JSON.parse(response.Settings[keys].Value);
      arr.push({
        ColumnHeading: response.Settings[keys].Name,
        ColumnElements: val,
      });
    }
    arr.forEach((arr1) => {
      filterJson.forEach((elem) => {
        if (elem.ColumnHeading == arr1.ColumnHeading) {
          elem.ColumnElements.forEach((elem1) => {
            arr1.ColumnElements.forEach((elem2) => {
              if (elem1.ColumnElement == elem2.ColumnElement) {
                elem1.isChecked = elem2.isChecked;
                elem1.disabled = elem2.disabled;
              }
            });
          });
        }
      });
      this.columHeadingCall(arr1);
      this.columnHeadingCall2(arr1);
      this.priceTypeCall(arr1);
    });
    this.cdr.detectChanges();
    this.isPreferenced = true;
    this.checkWarningMessage();
  }

  ngAfterContentChecked() {
    if (this.isPreferenced) {
      this.prefernceData.Name = this.reportType;
    }
    const arr = [];

    for (const keys in this.filterJson) {
      const value = JSON.stringify(this.filterJson[keys].ColumnElements);
      arr.push({ Name: this.filterJson[keys].ColumnHeading, Value: value });
    }
    if (!this.isFilterEmpty) {
      const customer = JSON.stringify({
        ColumnElement: "Customer",
        isChecked: this.custSelected,
      });
      arr.push({ Name: "Customer", Value: customer });
    }
    if (this.reportType === "PartsConsumedPerServiceOption") {
      const replacement = JSON.stringify({
        ColumnElement: "PartReplacement",
        isChecked: this.isIncludezeroreplacement,
      });
      arr.push({ Name: "PartReplacement", Value: replacement });
    }
    if (this.reportType === "PartsConsumedByInterval") {
      const replacement = JSON.stringify({
        ColumnElement: "IncludeZeroReplacement",
        isChecked: this.isIncludezeroreplacement,
      });
      arr.push({ Name: "IncludeZeroReplacement", Value: replacement });
      const zerounit = JSON.stringify({
        ColumnElement: "IncludeZeroUnitPrice",
        isChecked: this.isIncludeZeroUnitPrice,
      });
      arr.push({ Name: "IncludeZeroUnitPrice", Value: zerounit });
      const zeroquantity = JSON.stringify({
        ColumnElement: "IncludeZeroQuantity",
        isChecked: this.isIncludeZeroQuantity,
      });
      arr.push({ Name: "IncludeZeroQuantity", Value: zeroquantity });
      const creditFlag = JSON.stringify({
        ColumnElement: "IncludeCoreCredit",
        isChecked: this.isIncludeCoreCredit,
      });
      arr.push({ Name: "IncludeCoreCredit", Value: creditFlag });
    }
    if (
      this.isAnalysis ||
      this.isCostbyinterval ||
      this.isPartsConsumedbyIntervalReport
    ) {
      const freq = JSON.stringify({ freqValue: this.freqValue });
      arr.push({ Name: "Frequency", Value: freq });
    }
    if (this.isEscalatedPricesAvailable) {
      const escalatedprice = JSON.stringify({
        ColumnElement: "IncludeEscalatedPrices",
        isChecked: this.isEscaltedpartsinclude,
      });
      arr.push({ Name: "IncludeEscalatedPrices", Value: escalatedprice });
    }
    if (this.isVariabilityAvailable) {
      const variabilityFactors = JSON.stringify({
        ColumnElement: "IncludeVariabilityFactors",
        isChecked: this.isVariabilityFactorsinclude,
      });
      arr.push({
        Name: "IncludeVariabilityFactors",
        Value: variabilityFactors,
      });
    }
    if (this.isMiscPartsAvailable) {
      const miscPartsFactors = JSON.stringify({
        ColumnElement: "IncludeMiscFactors",
        isChecked: this.isMiscPartsinclude,
      });
      arr.push({ Name: "IncludeMiscFactors", Value: miscPartsFactors });
    }

    if (!this.isAnalysis && !this.isPartsConsumed && !this.isPartsListReport) {
      const price = JSON.stringify({ priceType: this.selectedPrice });
      arr.push({ Name: "PriceType", Value: price });
    } else {
      const price = JSON.stringify({ priceType: this.priceTypeArray });
      arr.push({ Name: "PriceType", Value: price });
    }
    this.prefernceData.Settings = arr;
    this.realTimeFilterChanged.next(this.prefernceData);
  }

  reportCurrencyModeSelect(event) {
    this.filterChanged = true;
  }
  getCurrencySourceType() {
    if (this.reportCurrencyMode === this.estimatCurrency) {
      return true;
    } else {
      return false;
    }
  }

  checkWarningMessage() {
    if (
      this.reportType === "PartsConsumedByInterval" ||
      this.reportType === "CostByInterval"
    ) {
      const durationFreq = this.duration / this.freqValue;
      this.dividedValue = this.duration / this.threshouldColumnCount;
      this.dividedValue = Math.round(this.dividedValue);
      this.freqValueChanged.next(durationFreq);
    }
  }
  includeMiscPartsSelected(event) {
    this.isMiscPartsinclude = event.target.checked;
    if (!this.reportForm.valid) {
      this.filterChanged = false;
    } else {
      this.filterChanged = true;
    }
  }
  errorPopup(response) {
    const modalRef = this.modalService.open(ConfirmAlertComponent, {
      centered: true,
      windowClass: "confirm-modal",
      backdropClass: "restore-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.alertMessage = response;
    modalRef.componentInstance.estimateRelease = true;
    modalRef.componentInstance.noCloseIcon = true;
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant("confirmOk"),
        cssClass: ["cat-btn-primary"],
        handler: (modal) => {
          modal.close(false);
          if (this.isShowReport) {
            this.gotointialView();
          }
          this.cdr.detectChanges();
        },
      },
    ];
  }
  reportTypeCall() {
    if (this.reportType === "RepairOptionDetails") {
      this.showRepair = true;
    }
    this.isCostbyinterval = false;
    if (this.reportType === "DetailByInterval") {
      this.isCostbyinterval = true;
    }
    this.isAnalysis = false;
    if (
      this.reportType === "AnalysisByPrice" ||
      this.reportType === "AnalysisByGrouping"
    ) {
      this.isAnalysis = true;
    }
  }

  reportTypeCall1() {
    this.isFilterEmpty = false;
    if (this.reportType === "AnalysisByGrouping") {
      this.isFilterEmpty = true;
    }
    this.isPartsConsumed = false;
    if (this.reportType === "PartsConsumedPerServiceOption") {
      this.isPartsConsumed = true;
      this.isIncludezeroreplacement = true;
    }
    this.isMiscPartsAvailable = false;
    this.isPartsListReport = false;
    if (this.reportType === "PartsList") {
      this.isMiscPartsAvailable = true;
      this.isPartsListReport = true;
    }
    this.isPartsConsumedbyIntervalReport = false;
    if (this.reportType === "PartsConsumedByInterval") {
      this.isPartsConsumedbyIntervalReport = true;
      this.isIncludezeroreplacement = true;
      this.isIncludeZeroUnitPrice = true;
      this.isIncludeZeroQuantity = true;
      this.isIncludeCoreCredit = true;
    }
    this.isRepairJobDetails = false;
    if (this.reportType === "RepairEventWithJobDetails") {
      this.isRepairJobDetails = true;
    }
    this.isRepairOptionsList = false;
    if (this.reportType === "RepairOptionsList") {
      this.isRepairOptionsList = true;
    }
    this.isPartsListForServiceOptions = false;
    if(this.reportType === "PartListForSelectedServiceOptions"){
      this.isPartsListForServiceOptions = true;
    }
  }
  PriceCall() {
    if (
      !this.isAnalysis &&
      !this.isPartsConsumed &&
      !this.isPartsListReport &&
      !this.isPartsConsumedbyIntervalReport &&
      !this.isRepairJobDetails
    ) {
      this.selectedPrice = this.priceType.filter((list) => {
        return list.toLowerCase() == "sell";
      });
    } else {
      this.selectedPrice = this.priceType;
      this.priceTypeArray = [];
      this.priceType.forEach((list) => {
        const priceObj = new Pricetype();
        priceObj.name = list;
        priceObj.isChecked = true;
        priceObj.disabled = false;
        this.priceTypeArray.push(priceObj);
      });
    }
  }
  currentTargetCall(event, categoryItem) {
    this.filterJson.filter((category) => {
      category.ColumnElements.filter((list) => {
        if (
          list.DisplayName == event.currentTarget.value &&
          list.ColumnElement == categoryItem.ColumnElement
        ) {
          list.isChecked = false;
        }
      });
    });
  }
  categoryFilterCall(event, categoryItem) {
    this.filterJson.filter((category) => {
      category.ColumnElements.filter((list) => {
        if (
          list.DisplayName == event.currentTarget.value &&
          list.ColumnElement == categoryItem.ColumnElement
        ) {
          list.isChecked = true;
        }
      });
    });
  }
  columHeadingCall(arr1) {
    if (arr1.ColumnHeading == "Customer") {
      this.customerList[0].Customer = arr1.ColumnElements.isChecked;
      this.custSelected = arr1.ColumnElements.isChecked;
      this.ShowCustomer = arr1.ColumnElements.isChecked;
    }
    if (arr1.ColumnHeading == "PartReplacement") {
      this.isIncludezeroreplacement = arr1.ColumnElements.isChecked;
    }
    if (arr1.ColumnHeading == "IncludeZeroReplacement") {
      this.includeCategoryOptions[0] = {
        DisplayName: "IncludeZeroReplacement",
        isChecked: arr1.ColumnElements.isChecked,
      };

      this.isIncludezeroreplacement = arr1.ColumnElements.isChecked;
    }
    if (arr1.ColumnHeading == "IncludeZeroUnitPrice") {
      this.includeCategoryOptions[1] = {
        DisplayName: "IncludeZeroUnitPrice",
        isChecked: arr1.ColumnElements.isChecked,
      };
      this.isIncludeZeroUnitPrice = arr1.ColumnElements.isChecked;
    }
    if (arr1.ColumnHeading == "IncludeZeroQuantity") {
      this.includeCategoryOptions[2] = {
        DisplayName: "IncludeZeroQuantity",
        isChecked: arr1.ColumnElements.isChecked,
      };
      this.isIncludeZeroQuantity = arr1.ColumnElements.isChecked;
    }
    if (arr1.ColumnHeading == "IncludeCoreCredit") {
      this.includeCategoryOptions[3] = {
        DisplayName: "IncludeCoreCredit",
        isChecked: arr1.ColumnElements.isChecked,
      };
      this.isIncludeCoreCredit = arr1.ColumnElements.isChecked;
    }
    if (arr1.ColumnHeading == "IncludeEscalatedPrices") {
      if (this.isEscalatedPricesAvailable) {
        this.isEscaltedpartsinclude = arr1.ColumnElements.isChecked;
      }
    }
  }
  columnHeadingCall2(arr1) {
    if (arr1.ColumnHeading == "IncludeVariabilityFactors") {
      if (this.isVariabilityAvailable) {
        this.isVariabilityFactorsinclude = arr1.ColumnElements.isChecked;
      }
    }
    if (arr1.ColumnHeading == "IncludeMiscFactors") {
      if (this.isMiscPartsAvailable) {
        this.isMiscPartsinclude = arr1.ColumnElements.isChecked;
      }
    }
    if (arr1.ColumnHeading == "Frequency") {
      this.reportForm.controls.frequency.setValue(
        arr1.ColumnElements.freqValue
      );
      this.frequency = this.reportForm.controls.frequency.value;
      this.freqValue = this.reportForm.controls.frequency.value;
    }
  }
  priceTypeCall(arr1) {
    if (arr1.ColumnHeading == "PriceType") {
      if (
        !this.isAnalysis &&
        !this.isPartsConsumed &&
        !this.isPartsListReport
      ) {
        this.selectedPrice[0] = arr1.ColumnElements.priceType[0];
      } else {
        this.selectedPrice = [];
        arr1.ColumnElements.priceType.forEach((data) => {
          this.priceTypeArray.forEach((price) => {
            if (data.name == price.name) {
              price.isChecked = data.isChecked;
              price.disabled = data.disabled;
              if (data.isChecked) {
                this.selectedPrice.push(data.name);
              }
            }
          });
        });
      }
    }
  }

  showErrMsg() {
    const modalRef = this.modalService.open(ConfirmAlertComponent, {
      centered: true,
      windowClass: "confirm-modal",
      backdropClass: "restore-backdrop",
      backdrop: "static",
    });
    modalRef.componentInstance.alertMessage =
      this.translate.instant("errorReportgenMsg") +
      "\n" +
      this.translate.instant("try_again");
    modalRef.componentInstance.estimateRelease = true;
    modalRef.componentInstance.noCloseIcon = true;
    modalRef.componentInstance.error = true;
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant("confirmOk"),
        cssClass: ["cat-btn-primary btn-width btn-mr"],
        handler: (modal) => {
          modal.close(false);
          this.onClose();
          if (this.popupflag == true) {
            this.onClose();
            this.popupflag = false;
          }
        },
      },
    ];
  }

  getContentType() {
    if (this.reportType === "PartsList") {
      return [this.contentType[2]];
    } else {
      return [this.contentType[1]];
    }
  }

  handleErrorMessage = (response) => {
    let hasMultipleErrors =
      response.FeedbackItems && response.FeedbackItems.length > 1
        ? true
        : false;
    if (hasMultipleErrors) {
      let errorMessage = this.translate.instant("serverErr");
      this.showSnackBar(errorMessage);
    } else {
      if (response.FeedbackItems && response.FeedbackItems.length > 0) {
        let errorMessage = response.FeedbackItems[0].Message;
        this.showErrorMsg(errorMessage);
      }
    }
  };

  showErrorMsg = (msg) => {
    let modalProps: {} = {
      centered: true,
      windowClass: "confirm-modal",
      backdropClass: "confirm-modal-backdrop",
      backdrop: "static",
      keyboard: false,
    };
    const modalRef = this.modalService.open(ConfirmAlertComponent, modalProps);
    modalRef.componentInstance.isMessage = true;
    modalRef.componentInstance.noCloseIcon = true;
    modalRef.componentInstance.timeFrame = true;
    modalRef.componentInstance.alertMessage = msg;
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant("confirmOk"),
        cssClass: ["cat-btn-primary"],
        handler: (modal) => {
          modal.close(false);
        },
      },
    ];
  };

  showSnackBar = (message) => {
    this.snackBar.open(message, "", {
      duration: 4000,
      panelClass: ["status-snack-bar"],
    });
  };

  downloadFile(blob, filename) {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.append(link);
    link.click();
    link.remove();
    window.addEventListener("focus", (e) => URL.revokeObjectURL(link.href), {
      once: true,
    });
  }
}
