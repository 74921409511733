// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  timeoutInterval: 30,
  accessTokenKey: 'accessToken',
  refreshTokenKey: 'refreshToken',
  userNameKey: 'userName',
  scope: 'profile openid offline_access ',
  devModeEnabled: false,
  tempAccessToken: '',
  authUrl: 'https://signin.cat.com/cwslogin.onmicrosoft.com',
  codeEndPoint: '/oauth2/v2.0/authorize?',
  codeEndPointNonProd: 'p=b2c_1a_p2_v1_signin_nonprod', 
  tokenEndPoint: 'as/token.oauth2',
  refreshEndPoint: '/as/revoke_token.oauth2',
  callbackUrl: 'https://calculator-dev.cat.com/',
  responseType: 'code',
  client_id: 'e34c8a94-dcb4-48d2-b924-b994b7bc25e7',
  logoutUrl: 'https://signin.cat.com/cwslogin.onmicrosoft.com/b2c_1a_p2_v1_signin_nonprod/oauth2/v2.0/logout',
  AntiToken: 'ADFNDFrtIOP',
  AppVersion: '21.0.0',
  AppInsightsKey: '2181b5c9-0e1e-494e-b9e7-0320fb487ee9',
  AppDynamicsKey: '',
  API_ENDPOINT_URL : 'https://calculator-internalapi-dev.cat.com',
  QC_API_ENDPOINT_URL : 'https://calculatoradvapi-dev.cat.com',
  GTM_ID : 'GTM-NFWNT3X'
 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */ // import 'zone.js/dist/zone-error';
