import { environment } from "environments/environment";

// Authentication & Authorization
export const accessTokenUrl =
  environment.API_ENDPOINT_URL + "/api/TokenManagement/AccessToken";
export const refreshTokenUrl =
  environment.API_ENDPOINT_URL + "/api/TokenManagement/RefreshToken";
export const logoutUrl =
  environment.API_ENDPOINT_URL + "/api/TokenManagement/RevokeToken";
export const getUserProfile =
  environment.API_ENDPOINT_URL + "/api/Security/UserProfileInfo";
export const loadWorkGroup =
  environment.API_ENDPOINT_URL + "/api/Security/LoadWorkgroup";

export const testCall =
  environment.API_ENDPOINT_URL + "/api/Workgroup/Settings/TestCall";

export const numberRegex = /^(\d*\.)?\d+$/;
export const numberAplhabetsRegex = /^[a-zA-Z0-9]+$/;
export const decimalRegex = /^-?[0-9]*[.]{0,1}[0-9]+$/;
export const percentSymbRegex = /^[0-9]*[.]{0,1}[0-9]+(%)?$/g;
export const wholeNumRegex = /^[0-9]*[.]{0,1}[0-9]+$/g;
export const alphaNumericWithChar = /^(?:\s*[a-zA-Z0-9]{2,}\s*)*/;
export const urlPatternRegex =
  /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
export const mobOrTabCheckReg1 =
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;
export const mobOrTabCheckReg2 =
  /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;
export const numberAplhabetsLimitedCharRegex = /^[\w&., \]\/\-\\_\'()[]*$/;

export const getWorkgroupDetails =
  environment.API_ENDPOINT_URL +
  "/api/AdvancedEstimator/Workgroup/Settings/Load";
export const getGlobalWorkgroupDetails =
  environment.API_ENDPOINT_URL +
  "/api/AdvancedEstimator/Workgroup/Global/Settings/Load";
// Settings
export const getEnginePerformanceDetails =
  environment.API_ENDPOINT_URL + "/api/EnginePerformance/Load";
export const getWorkgroupSelectionValues =
  environment.API_ENDPOINT_URL +
  "/api/AdvancedEstimator/Workgroup/Settings/SelectionValues";

export const addEnginePerformanceDetails =
  environment.API_ENDPOINT_URL + "/api/EnginePerformance/Merge";
export const mergeGlobalEnginePerformanceDetails =
  environment.API_ENDPOINT_URL +
  "/api/EnginePerformance/MergeGlobalEnginePerformance";
export const mergeCustomEnginePerformanceDetails =
  environment.API_ENDPOINT_URL +
  "/api/EnginePerformance/MergeCustomEnginePerformance";
export const editEnginePerformanceDetails =
  environment.API_ENDPOINT_URL + "/api/EnginePerformance/Save";
export const saveGlobalEnginePerformanceDetails =
  environment.API_ENDPOINT_URL +
  "/api/EnginePerformance/SaveGlobalEnginePerformance";
export const saveCustomEnginePerformanceDetails =
  environment.API_ENDPOINT_URL +
  "/api/EnginePerformance/SaveCustomEnginePerformance";

export const getCustomers =
  environment.API_ENDPOINT_URL + "/api/Customer/Customers";
export const addCustomer = environment.API_ENDPOINT_URL + "/api/Customer/Add";
export const addCustomerlist =
  environment.API_ENDPOINT_URL + "/api/Customer/Upload";
export const editCustomer = environment.API_ENDPOINT_URL + "/api/Customer/Edit";
export const deleteCustomer =
  environment.API_ENDPOINT_URL + "/api/Customer/Delete?customerUniqueId=";
export const getParts =
  environment.API_ENDPOINT_URL + "/api/Workgroup/Parts/Pricing/PartPrice";
export const saveWorkGroup =
  environment.API_ENDPOINT_URL +
  "/api/AdvancedEstimator/Workgroup/Settings/Save";
export const copyWorkGroup =
  environment.API_ENDPOINT_URL + "/api/Workgroup/Settings/copyWG?workgroupId=";
export const mergeWorkGroup =
  environment.API_ENDPOINT_URL +
  "/api/AdvancedEstimator/Workgroup/Settings/Merge";
export const saveWorkGroupGlobal =
  environment.API_ENDPOINT_URL +
  "/api/AdvancedEstimator/Workgroup/Global/Settings/Save";
export const mergeWorkGroupGlobal =
  environment.API_ENDPOINT_URL +
  "/api/AdvancedEstimator/Workgroup/Global/Settings/Merge";
export const savePartPrice =
  environment.API_ENDPOINT_URL + "/api/Workgroup/Parts/Pricing/Save";
export const editPartPrice =
  environment.API_ENDPOINT_URL + "/api/Workgroup/Parts/Pricing/Merge";
export const clearCacheData =
  environment.API_ENDPOINT_URL + "/api/Workgroup/Parts/Pricing/Cache";
export const compareSave =
  environment.API_ENDPOINT_URL +
  "/api/AdvancedEstimator/Workgroup/Settings/ComparisonSave";
export const exportEnginePerformanceAsExcel =
  environment.API_ENDPOINT_URL + "/api/EnginePerformance/Export";
export const exportEnginePerformanceAsText =
  environment.API_ENDPOINT_URL + "/api/EnginePerformance/Text";
export const saveCustomer = environment.API_ENDPOINT_URL + "/api/Customer/Save";
export const getSMCS =
  environment.API_ENDPOINT_URL +
  "/api/AdvancedEstimator/Workgroup/Global/Settings/SMCSTypes";
export const getSMCSData =
  environment.API_ENDPOINT_URL +
  "/api/AdvancedEstimator/Workgroup/Global/Settings/SMCSGroupingInfo?smcsType=";

export const saveUserPreferences =
  environment.API_ENDPOINT_URL + "/api/UserPreferences/Save";
export const getUserPreferences =
  environment.API_ENDPOINT_URL + "/api/UserPreferences/Load?id=";
export const restoreArchived =
  environment.API_ENDPOINT_URL + "/api/Plan/Restore?planNumber=";

export const getGeneralPreferences =
  environment.API_ENDPOINT_URL + "/api/GeneralPreferences/Load";
export const mergeGeneralPreferences =
  environment.API_ENDPOINT_URL + "/api/GeneralPreferences/Merge";
export const saveGeneralPreferences =
  environment.API_ENDPOINT_URL + "/api/GeneralPreferences/Save";

export const getPartsPricingFile =
  environment.API_ENDPOINT_URL + "/api/PartsPricingFile/Info";
export const uploadPartsPicingFile =
  environment.API_ENDPOINT_URL + "/api/PartsPricingFile/Upload";
export const loadPartsPricingFile =
  environment.API_ENDPOINT_URL + "/api/PartsPricingFile/load";

export const smcsLoadData =
  environment.API_ENDPOINT_URL +
  "/api/QuickEstimator/Workgroup/Global/Settings/Load";
export const smcsMergeData =
  environment.API_ENDPOINT_URL +
  "/api/QuickEstimator/Workgroup/Global/Settings/Merge";
export const smcsSaveeData =
  environment.API_ENDPOINT_URL +
  "/api/QuickEstimator/Workgroup/Global/Settings/Save";

// product details
export const manufacturerData =
  environment.API_ENDPOINT_URL + "/api/ProductInfo/Manufacturers";
export const familyData =
  environment.API_ENDPOINT_URL + "/api/ProductInfo/Families";
export const modalData =
  environment.API_ENDPOINT_URL + "/api/ProductInfo/Models";
export const serialNumberData =
  environment.API_ENDPOINT_URL + "/api/ProductInfo/SerialNumbers";
export const productSearchData =
  environment.API_ENDPOINT_URL + "/api/ProductInfo/Info";
export const commercialEngineData =
  environment.API_ENDPOINT_URL + "/api/ProductInfo/CommercialEngines";

// Finalize
export const estimateFinalize =
  environment.API_ENDPOINT_URL + "/api/Plan/EstimateFinalize";

// PEEK SOC and Builder
export const builderPartsExpert =
  environment.API_ENDPOINT_URL + "/api/Definitions/PeekBuilderExternalXML";
export const builderXml =
  environment.API_ENDPOINT_URL + "/api/Definitions/PeekBuilderXML";
export const builderXmlV2 =
  environment.API_ENDPOINT_URL + "/api/Definitions/PeekBuilderXMLV2";
export const peekSOC =
  environment.API_ENDPOINT_URL + "/api/Definitions/PeekSOC";
export const peekSOCV2 =
  environment.API_ENDPOINT_URL + "/api/Definitions/PeekSOCV2";
export const calculateEngineData =
  environment.API_ENDPOINT_URL +
  "/api/PreCalculation/CalculateEnginePerformanceData";
export const factorImportStatus =
  environment.API_ENDPOINT_URL + "/api/Plan/FactorImportStatus";
export const factorRevisionEvaluation =
  environment.API_ENDPOINT_URL + "/api/Plan/CurrentRevisionEvaluation";
export const initiateFactorsImport =
  environment.API_ENDPOINT_URL + "/api/Plan/InitiateFactorRevision";
export const initiateSelectFactorsImport =
  environment.API_ENDPOINT_URL + "/api/Plan/InitiateSelectiveFactorRevision";
export const estimateApply =
  environment.API_ENDPOINT_URL + "/api/Plan/EstimateAndApply";
export const restoreVersionAPI =
  environment.API_ENDPOINT_URL + "/api/Plan/RestoreVersion";
export const selectionValues =
  environment.API_ENDPOINT_URL + "/api/Plan/SelectionValues";
export const estimateDetails =
  environment.API_ENDPOINT_URL + "/api/Plan/PlanDetails";
export const updateArchive =
  environment.API_ENDPOINT_URL + "/api/Plan/Archive?planNumber=";
export const startPlan = environment.API_ENDPOINT_URL + "/api/Plan/StartPlan";
export const checkEstimateLock =
  environment.API_ENDPOINT_URL + "/api/Plan/LockAndLoad?planNumber=";
export const pmWizardDetailsBeforeStartPlan =
  environment.API_ENDPOINT_URL + "/api/PreCalculation/ApplyFilter";
export const fetchFluidDetails =
  environment.API_ENDPOINT_URL + "/api/PreCalculation/GetFluidPrice";
export const calculateOilUsage =
  environment.API_ENDPOINT_URL + "/api/PreCalculation/CalculateOilUsage";
export const calculateFuelUsage =
  environment.API_ENDPOINT_URL + "/api/PreCalculation/CalculateFuelUsage";
export const deleteEstimate =
  environment.API_ENDPOINT_URL + "/api/Plan/RemovePlan";
export const calculatecoolantUsage =
  environment.API_ENDPOINT_URL +
  "/api/PreCalculation/CalculateCoolantUsage?planNumber=";
export const undoEstimateChange =
  environment.API_ENDPOINT_URL + "/api/Plan/Undo";
export const redoEstimateChange =
  environment.API_ENDPOINT_URL + "/api/Plan/Redo";
export const undoRedoAvailability =
  environment.API_ENDPOINT_URL + "/api/Plan/GetUndoRedoAvailability";
export const saveNotes = environment.API_ENDPOINT_URL + "/api/Plan/SaveNote";
export const previewRefreshSOCData =
  environment.API_ENDPOINT_URL + "/api/Plan/PreviewDefinitionRefresh";
export const applyRefreshSOCData =
  environment.API_ENDPOINT_URL + "/api/Plan/ApplyDefinitionRefresh";
export const applyFactors =
  environment.API_ENDPOINT_URL + "/api/Plan/ApplyFactors";
export const comparisonView =
  environment.API_ENDPOINT_URL + "/api/Comparison/ProduceComparison?";
export const comparisonEdit =
  environment.API_ENDPOINT_URL + "/api/Comparison/LockAndProduceComparison?";

// quick calculator
export const copyPlan = environment.API_ENDPOINT_URL + "/api/Plan/Copy";
export const copyPlanToWorkgroup =
  environment.API_ENDPOINT_URL + "/api/Plan/CopyPlanToWorkgroup";
export const getEstimate = environment.API_ENDPOINT_URL + "/api/Plan/List";
// quick calculator
export const manufacturerDetails =
  environment.QC_API_ENDPOINT_URL + "/api/Manufacturer/Manufacturer";
export const familyDetails =
  environment.QC_API_ENDPOINT_URL + "/api/Family/Family";
export const productDetails =
  environment.QC_API_ENDPOINT_URL + "/api/Product/Product";
export const serialNumbers =
  environment.QC_API_ENDPOINT_URL + "/api/Product/Product/serialnumbers";
export const searchPrefix =
  environment.QC_API_ENDPOINT_URL + "/api/Product/serialprefix";
export const estimateCalculation =
  environment.QC_API_ENDPOINT_URL + "/api/QuickEstimate/QuickEstimate";
export const priceFileUpload =
  environment.QC_API_ENDPOINT_URL + "/api/Admin/RegionalPriceUploadSubmit";
export const clearCache =
  environment.QC_API_ENDPOINT_URL + "/api/Admin/RegionalPriceClearCache";
export const reloadCache =
  environment.QC_API_ENDPOINT_URL + "/api/Admin/RegionalPriceReloadCache";

export const releasePlan = environment.API_ENDPOINT_URL + "/api/Plan/Release";

export const getpeekSOC = "/assets/mock-data/peekbuilder.json";
export const basicDetailsWorkGroup =
  environment.API_ENDPOINT_URL + "/api/ProductInfo/WorkGroups";

// MVP phase calculator
// Estimate - generate report - Need to update proper API
export const listSupported =
  environment.API_ENDPOINT_URL + "/api/Report/ListSupported?planNumber=";
export const getPreviewReport =
  environment.API_ENDPOINT_URL + "/api/Report/CreateReportPreview?planNumber=";
export const downloadReport =
  environment.API_ENDPOINT_URL + "/api/Report/DownloadReport?planNumber=";
export const sourceName = "ExternallyProvidedForPlan";

// setings wg currency
export const currencyList =
  environment.API_ENDPOINT_URL + "/api/WorkGroupSettings/CurrencyList";

// MVP Estimate Versioning

export const savePoint =
  environment.API_ENDPOINT_URL + "/api/Plan/CreateSavePoint?";
export const estVersion =
  environment.API_ENDPOINT_URL + "/api/Plan/EstimateHistory?";
export const resolveVersionAPI =
  environment.API_ENDPOINT_URL + "/api/Plan/ResolveVersion";

// MVP Grief Process

export const customSaveGreif =
  environment.API_ENDPOINT_URL + "/api/Plan/SaveCustomData";

// admin

export const workgroupList =
  environment.API_ENDPOINT_URL + "/api/Security/GetOrganizationDetails";
export const organizationList =
  environment.API_ENDPOINT_URL + "/api/Security/GetAllOrganizations";
export const setOrgRole =
  environment.API_ENDPOINT_URL + "/api/Security/SaveOrganizationAffiliations";
export const adduserworkgroupList =
  environment.API_ENDPOINT_URL + "/api/Security/SaveWorkGroupAffiliations";
export const saveworkgroupList =
  environment.API_ENDPOINT_URL + "/api/Security/SaveWorkgroup";
export const removeworkgroupList =
  environment.API_ENDPOINT_URL + "/api/Security/RemoveWorkgroupAffiliation";

export const addOrganization =
  environment.API_ENDPOINT_URL + "/api/Security/SaveOrganization";
export const marketingOrganization =
  environment.API_ENDPOINT_URL + "/api/PartsPricing/SalesOrganization";
export const salesArea =
  environment.API_ENDPOINT_URL + "/api/PartsPricing/SalesArea";
export const removeWorkgroup =
  environment.API_ENDPOINT_URL + "/api/security/RemoveWorkgroup";
export const removeUserWg =
  environment.API_ENDPOINT_URL + "/api/security/RemoveOrganizationAffiliations";
export const deactiveUser =
  environment.API_ENDPOINT_URL + "/api//Security/Deactivate";
export const reactivateUser =
  environment.API_ENDPOINT_URL + "/api/Security/Reactivate";

// Cost Schedule
export const generateEvent =
  environment.API_ENDPOINT_URL + "/api/PreCalculation/GenerateEventSchedule";
export const repairScheduleInput =
  environment.API_ENDPOINT_URL + "/api/PreCalculation/ApplyFilterOnPlan";

// Global Settings
export const uploadEngineperformance =
  environment.API_ENDPOINT_URL + "/api/EnginePerformance/UploadFile?type";
export const exportEngineperformance =
  environment.API_ENDPOINT_URL + "/api/EnginePerformance/Export?type";
export const getGlobalParts =
  environment.API_ENDPOINT_URL + "/api/QuickEstimator/PartsPricing/Global/Load";
export const saveGlobalParts =
  environment.API_ENDPOINT_URL + "/api/QuickEstimator/PartsPricing/Global/Save";
export const mergeGlobalParts =
  environment.API_ENDPOINT_URL +
  "/api/QuickEstimator/PartsPricing/Global/Merge";
export const underCarriageLoad =
  environment.API_ENDPOINT_URL + "/api/UnderCarriageVariability/Load";
export const underCarriageSave =
  environment.API_ENDPOINT_URL + "/api/UnderCarriageVariability/Save";
export const machineVariablityLoad =
  environment.API_ENDPOINT_URL + "/api/MachineMaintenanceVariability/Load";
export const machineCariabilitySave =
  environment.API_ENDPOINT_URL + "/api/MachineMaintenanceVariability/Save";
export const laborRateLoad =
  environment.API_ENDPOINT_URL + "/api/GlobalWorkgroupSettings/LoadLaborRate";
export const laborRateSave =
  environment.API_ENDPOINT_URL + "/api/GlobalWorkgroupSettings/SaveLaborRate";
export const perDiemRateLoad =
  environment.API_ENDPOINT_URL + "/api/GlobalWorkgroupSettings/LoadPerDiemRate";
export const perDiemRateSave =
  environment.API_ENDPOINT_URL + "/api/GlobalWorkgroupSettings/SavePerDiemRate";
export const travelLoad =
  environment.API_ENDPOINT_URL + "/api/GlobalWorkgroupSettings/LoadTravel";
export const TravelSave =
  environment.API_ENDPOINT_URL + "/api/GlobalWorkgroupSettings/SaveTravel";
export const fluidLoad =
  environment.API_ENDPOINT_URL + "/api/GlobalWorkgroupSettings/LoadFluid";
export const fluidSave =
  environment.API_ENDPOINT_URL + "/api/GlobalWorkgroupSettings/SaveFluid";
export const adjustmentLoad =
  environment.API_ENDPOINT_URL + "/api/GlobalWorkgroupSettings/LoadAdjustment";
export const adjustmentSave =
  environment.API_ENDPOINT_URL + "/api/GlobalWorkgroupSettings/SaveAdjustment";
export const SMCSConfigurationLoad =
  environment.API_ENDPOINT_URL +
  "/api/GlobalWorkgroupSettings/LoadSMCSConfiguration";
export const SMCSConfigurationSave =
  environment.API_ENDPOINT_URL +
  "/api/GlobalWorkgroupSettings/SaveSMCSConfiguration";
export const generalConfigurationLoad =
  environment.API_ENDPOINT_URL +
  "/api/GlobalWorkgroupSettings/LoadGeneralConfiguration";
export const generalConfigurationSave =
  environment.API_ENDPOINT_URL +
  "/api/GlobalWorkgroupSettings/SaveGeneralConfiguration";
export const getGlobalPartsRefresh =
  environment.API_ENDPOINT_URL +
  "/api/QuickEstimator/PartsPricing/Global/RefreshPartsMapping";
export const adCrossRef =
  environment.API_ENDPOINT_URL + "/api/QuickEstimator/PartsPricing/Global/Save";
export const loadEnginePerf =
  environment.API_ENDPOINT_URL +
  "/api/EnginePerformance/LoadPerformanceDataSet";
export const loadEnginePerfEdit =
  environment.API_ENDPOINT_URL +
  "/api/EnginePerformance/LoadPerformanceDataSet";
export const globalPartsParicingInfo =
  environment.API_ENDPOINT_URL +
  "/api/QuickEstimator/PartsPricing/Global/PartsPricingInfo";

// Quick-Estimator New
export const quickPeekSoc =
  environment.API_ENDPOINT_URL + "/api/QuickEstimator/QuickPeekSOC";
export const quickAttemptEstimate =
  environment.API_ENDPOINT_URL + "/api/QuickEstimator/AttemptEstimate";
export const quickEstimateDownloadReport =
  environment.API_ENDPOINT_URL + "/api/QuickEstimator/DownloadReport";
export const compareWG =
  environment.API_ENDPOINT_URL +
  "/api/AdvancedEstimator/Workgroup/Settings/Comparison";

// Quick-Estimator Settings
export const quckEstimatorSettingsGet =
  environment.API_ENDPOINT_URL + "/api/QuickEstimator/WorkGroup/Settings/Load";
export const saveQEWorkGroup =
  environment.API_ENDPOINT_URL + "/api/QuickEstimator/WorkGroup/Settings/Save";
export const mergeQEWorkGroup =
  environment.API_ENDPOINT_URL + "/api/QuickEstimator/WorkGroup/Settings/Merge";
export const qePartsGet =
  environment.API_ENDPOINT_URL +
  "/api/QuickEstimator/PartsPricing/Workgroup/Load";
export const saveqeParts =
  environment.API_ENDPOINT_URL +
  "/api/QuickEstimator/PartsPricing/Workgroup/Save";
export const mergeqeParts =
  environment.API_ENDPOINT_URL +
  "/api/QuickEstimator/PartsPricing/Workgroup/Merge";
export const workgroupPartsParicingInfo =
  environment.API_ENDPOINT_URL +
  "/api/QuickEstimator/PartsPricing/Workgroup/PartsPricingInfo";
export const qePartsDetails =
  environment.API_ENDPOINT_URL +
  "/api/QuickEstimator/PartsPricing/WorkGroup/RefreshPartsMapping";
export const qeCrossRef =
  environment.API_ENDPOINT_URL +
  "/api/QuickEstimator/PartsPricing/Workgroup/Save";

export const loadTrainingContent =
  environment.API_ENDPOINT_URL + "/api/TrainingContent/Load?";
export const saveTrainingContent =
  environment.API_ENDPOINT_URL + "/api/TrainingContent/Save";

export const deleteUserWg =
  environment.API_ENDPOINT_URL + "/api/security/ListWorkgroups";
export const restoreUserWg =
  environment.API_ENDPOINT_URL + "/api/security/RestoreWorkgroup?workgroupId=";

// Request elevated access profile
export const getUserAccessLevel =
  environment.API_ENDPOINT_URL + "/api/Security/SelectionValues";
export const getRequestElevatedAccess =
  environment.API_ENDPOINT_URL + "/api/UserAccess/ListAccessLevels";
export const userRequestElevation =
  environment.API_ENDPOINT_URL + "/api/UserAccess/RequestElevation";
export const getUserListRequests =
  environment.API_ENDPOINT_URL + "/api/UserAccess/ListRequests";

// Combining estimates to one report
export const listSupportedAggregateReports =
  environment.API_ENDPOINT_URL + "/api/AggregateReports/ListSupported?";
export const createAggregateReportPreview =
  environment.API_ENDPOINT_URL + "/api/AggregateReports/CreateReportPreview?";
export const downloadAggregateReport =
  environment.API_ENDPOINT_URL + "/api/AggregateReports/DownloadReport?";
// Region Settings Request Dashboard
export const regionSettingsAccess =
  environment.API_ENDPOINT_URL + "/api/RegionSettingsAccess/ListAllRequests";
export const approveRequest =
  environment.API_ENDPOINT_URL + "/api/RegionSettingsAccess/Approve";
export const rejectRequest =
  environment.API_ENDPOINT_URL + "/api/RegionSettingsAccess/Reject";
// Request level request dashboard
export const getListAllRequests =
  environment.API_ENDPOINT_URL + "/api/UserAccess/ListAllRequests";
export const approve = environment.API_ENDPOINT_URL + "/api/UserAccess/Approve";
export const reject = environment.API_ENDPOINT_URL + "/api/UserAccess/Reject";

export const sendQERequest =
  environment.API_ENDPOINT_URL + "/api/RegionSettingsAccess/Request";
export const getRequestsList =
  environment.API_ENDPOINT_URL + "/api/RegionSettingsAccess/ListRequests";

// notification preferance for organization admin
export const saveNotificationSettings =
  environment.API_ENDPOINT_URL + "/api/UserSettings/Save";
export const loadNotificationSettings =
  environment.API_ENDPOINT_URL + "/api/UserSettings/Load";
export const loadNotificationData =
  environment.API_ENDPOINT_URL + "/api/Alerts/ListAllRequests ";

// Restore deleted estimate
export const restoreEstimate =
  environment.API_ENDPOINT_URL + "/api/Plan/RestoreDeleted";

// Report for Admin
export const downloadAdminReport =
  environment.API_ENDPOINT_URL + "/api/Report/DownloadUserReport";
export const individualAccess =
  environment.API_ENDPOINT_URL + "/api/Security/GetAllUsers";
export const saveIndividualAccess =
  environment.API_ENDPOINT_URL + "/api/Security/SavePrivilegeAccess";
export const individualAccessDrawer =
  environment.API_ENDPOINT_URL + "/api/Security/GetAllUsers";
export const deleteDealer =
  environment.API_ENDPOINT_URL + "/api/Security/RemoveUsers";

// Report for Access
export const downloadAccessReport =
  environment.API_ENDPOINT_URL + "/api/Report/DownloadUserReport";

// Alerts
export const getAlerts =
  environment.API_ENDPOINT_URL + "/api/Alerts/ListApplicationAlerts";
export const mergeAlerts =
  environment.API_ENDPOINT_URL + "/api/Alerts/MergeApplicationAlerts";
export const saveAlerts =
  environment.API_ENDPOINT_URL + "/api/Alerts/SaveApplicationAlerts";
export const alertMessage =
  environment.API_ENDPOINT_URL + "/api/Alerts/ListApplicationLiveAlerts";

// Flat Rate Exchange
export const loadFre =
  environment.API_ENDPOINT_URL + "/api/WorkGroupSettings/LoadFRE";
export const LoadFreService =
  environment.API_ENDPOINT_URL + "/api/Definitions/LoadFREServiceDetails";
export const saveFre =
  environment.API_ENDPOINT_URL + "/api/WorkGroupSettings/SaveFRE";
export const loadFullFre =
  environment.API_ENDPOINT_URL + "/api/Definitions/LoadFullFREServiceDetails";
//Repricing
export const initiateRepricing =
  environment.API_ENDPOINT_URL + "/api/BatchFactorRevision/Initiate?";
export const cancelRepricing =
  environment.API_ENDPOINT_URL + "/api/BatchFactorRevision/RequestCancel?id=";
export const onGoingRevisions =
  environment.API_ENDPOINT_URL + "/api/BatchFactorRevision/OngoingRevisions";
export const factorImport =
  environment.API_ENDPOINT_URL +
  "/api/BatchFactorRevision/FactorImportStatus?id=";

//SOC Data Refresh
export const previewDataForSOCRefresh =
  environment.API_ENDPOINT_URL + "/api/Plan/PreviewDefinitionRefresh";
export const applySOCDataRefresh =
  environment.API_ENDPOINT_URL + "/api/Plan/ApplyDefinitionRefresh";

//Multi Product APIs
export const mpePeekCall =
  environment.API_ENDPOINT_URL + "/api/MultiProductDefinitions/Peek";
export const mpeBuilderXMLInitiatePeek =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductDefinitions/InitiatePeekBuilderXML";
export const mpePeekStatus =
  environment.API_ENDPOINT_URL + "/api/MultiProductDefinitions/PeekStatus";
export const mpeCancelPeekRequest =
  environment.API_ENDPOINT_URL + "/api/MultiProductDefinitions/RequestCancel";
export const mpeLoadProducts =
  environment.API_ENDPOINT_URL + "/api/MultiProductDefinitions/LoadDetails";
export const mpeStartPlan =
  environment.API_ENDPOINT_URL + "/api/MultiProductPlans/StartPlan";
export const mpeCheckStartPlanStatus =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductPlans/CheckPlanStartStatus?requestId=";
export const mpeStartPlanDetail =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductPlans/StartPlanDetail?requestId=";
export const mpeInitiateFactorImport =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductPlans/InitiateFactorRevision?PlanNumber=";
export const mpeLoadSpecific =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductPlans/LoadSpecific?PlanNumber=";
export const loadSummary =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductPlans/LoadSummary?PlanNumber=";
export const loadSpecific =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductPlans/LoadSpecific?PlanNumber=";
export const estimateApi =
  environment.API_ENDPOINT_URL + "/api/MultiProductPlans/Estimate?PlanNumber=";
export const checkEstimateStatus =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductPlans/CheckEstimateStatus?PlanNumber=";
export const estimateAndApply =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductPlans/EstimateAndApply?PlanNumber=";
export const lockAndLoadSummary =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductPlans/LockAndLoadSummary?PlanNumber=";
export const mpeInitiateSelectiveFactorImport =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductPlans/InitiateSelectiveFactorRevision?PlanNumber=";
export const mpeUploadAndSummarizeBuilderXML =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductDefinitions/UploadAndSummarizeBuilderXML";

//MPE Report
export const listSupportedMPE =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductReport/ListSupported?planNumber=";
export const getPreviewReportMpe =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductReport/ReportPreviewDetail?planNumber=";
export const createReport =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductReport/CreateReport?planNumber=";
export const CheckReportStatus =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductReport/CheckReportStatus?planNumber=";
export const downloadReportMPE =
  environment.API_ENDPOINT_URL +
  "/api/MultiProductReport/DownloadReportDetail?planNumber=";
export const totalCostDetailURL =
  environment.API_ENDPOINT_URL + "/api/TotalCost/TotalCostDetails"
export const deleteTCO = environment.API_ENDPOINT_URL + "/api/TotalCost/RemoveTotalCost?TotalCostNumber=";
export const getTCOList = environment.API_ENDPOINT_URL + "/api/TotalCost/List"
export const factors = [
  { category: "Parts", percentage: "0", isUserInputInvalid: false },
  { category: "Labor", percentage: "0", isUserInputInvalid: false },
  { category: "Miscellaneous", percentage: "0", isUserInputInvalid: false },
  { category: "Fluids", percentage: "0", isUserInputInvalid: false },
  { category: "Travel", percentage: "0", isUserInputInvalid: false },
];
export const createTcoUrl = environment.API_ENDPOINT_URL + "/api/TotalCost/StartEstimate";
export const saveTcoUrl = environment.API_ENDPOINT_URL + "/api/TotalCost/EstimateAndApply"
export const noOfDaysInOneYear = 366;
export const noOfHoursInOneYear = 366 * 24;
export const noOfDaysInNineMonths = 275;
export const noOfHoursInNineMonths = 275 * 24;
export const noOfDaysInThreeMonths = 92;
export const noOfHoursInThreeMonths = 92 * 24;
