import { Component, OnInit } from "@angular/core";
import {
  Router,
  ActivatedRoute,
  Event,
  NavigationEnd,
  NavigationStart,
} from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

import { TranslateService } from "@ngx-translate/core";
import { NgbConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil, take, retry } from "rxjs/operators";
import { error } from "protractor"; // TODO Remove after removing dependency with http-interceptor
import { interval, Subject, Subscription } from "rxjs";
import sha256 from "crypto-js/sha256";
import { MessageBar } from "@cat-digital-workspace/shared-ui-core";

import { AuthService } from "@app/services/authenticate/auth.service";
import { environment } from "environments/environment";
import { ConfirmAlertComponent } from "@app/components/confirm-alert/confirm-alert.component";
import { PrivacyPolicyComponent } from "@app/common/privacy-policy/privacy-policy.component";
import { CcModal } from "@app/components/modal/index";
import {
  UserService,
  DefaultUserWGPreferences,
  GeneralUserPreferences,
  UserLanguage,
  UserDateFormat,
  UserTimeFormat,
} from "@app/services/misc/user.service";
import { SubjectService } from "@app/services/data/subject.service";
import { DataService } from "@app/services/shared/storage/data.service";
import { AppInsightsService } from "@app/services/misc/App-Insights/app-insights.service";
import { EstimatePricingService } from "@app/services/estimate-pricing/estimate-pricing.service";
import { WGRole, SysRole } from "@app/services/models/roles";
import { EstimateType } from "@app/services/models/misc/general";
import { QuickEstimateServiceNew } from "@app/quick-estimator-new/quick-estimator-new-services/quick-estimate-data-share.service";
import { WorkgroupService } from "@app/services/workgroup/workgroup.service";
import { PartsExpertWorkgroupComponent } from "@app/parts-expert-workgroup/parts-expert-workgroup.component";
import { DateDifferencePipe } from "@app/common/pipes/date-difference.pipe";
import { RouterTitleServiceService } from "@app/router-title-service.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  isButtonDisable = false;
  generalChange = false;
  estimateSettingsChanged = false;
  perdiemChange = false;
  travelChange = false;
  dp: string;
  errorMsg = "";
  formChanged = false;
  isEdit = true;
  quickEstimatorChanged = false;
  valueChanged = false;
  adjustmentFormvalueChange = false;
  profileValueChanged = false;
  activeTabname;
  hideHeaderTabs: boolean;
  isAdminClicked: boolean;
  machine = EstimateType.Machine;
  popoverVariable;
  sysAdmin = SysRole.Admin;
  hideRightSideHeaderTabs = false;
  workGroupDdlData: any = [];
  partsChange = false;
  underCarriageChanged = false;
  showFooter = true;
  priceEscalationChange = false;
  hideNavBarBand = false;
  isElevRequestNotification = false;
  isQERequestNotification = false;
  isElevReqEmailUpdate = false;
  isQEReqEmailUpdate = false;
  currentActiveGroups;
  initialCheck: any = false;
  isLivealert: any;

  showNotificationIcon: any = false;
  alertBorder: any;

  showBellIcon = true;
  daysCount: number;
  notificationData: any;
  count = 0;
  countData: any;
  currentUrl = "";
  currentCwsObj;
  messageRef1: any;
  freChanged = false;
  private templateNames = [
    "Workgroup Settings",
    "Quick Estimator Settings",
    "Admin",
    "Estimate",
    "Global Settings",
    "Quick Estimator",
  ];
  protected closeMessage = new Subject<boolean>();
  alertmessage: any;
  showBanner: any;
  alertAlreadyDisplayed = false;
  bannerToUpdate = false;
  updateBannerConfig;
  newUsercheckFlag = false;
  mpeFlagcheck = false;
  mpeFlagval: Subscription;
  isUserOrgIntermediate: boolean = false;
  isUserWgRoleReadOnly: boolean = false;
  constructor(
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    public modalService: NgbModal,
    private listenChange: SubjectService,
    public activeModal: NgbActiveModal,
    private estimateService: QuickEstimateServiceNew,
    public activateRouter: ActivatedRoute,
    public modal: CcModal,
    public messageBar: MessageBar,
    public wgService: WorkgroupService,
    private userService: UserService,
    public dataService: DataService,
    private appInsights: AppInsightsService,
    private estimatePricingService: EstimatePricingService,
    private quickEstimatorService: QuickEstimateServiceNew,
    private dialog: MatDialog,
    private ngbConfig: NgbConfig,
    private dateDifferencePipe: DateDifferencePipe,
    private appRoutingService: RouterTitleServiceService,
    public subjectService: SubjectService
  ) {
    this.dataService.showLoadingLoader = true;
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.dataService.showLoadingLoader = false;
      }
    });

    this.translate.addLangs([
      "en",
      "es",
      "fr",
      "pt",
      "de",
      "id",
      "it",
      "ja",
      "ru",
      "zh",
      "ko",
      "tr",
    ]);
    this.translate.setDefaultLang("en");
    if (localStorage.getItem("locale") !== null) {
      this.translate.use(localStorage.getItem("locale"));
    }
    this.hideHeaderTabs = false;
    this.ngbConfig.animation = false;
    this.mpeFlagval = this.subjectService.mpeFlag$.subscribe((value) => {
      this.mpeFlagcheck = value;
    });
  }

  setLocalizationPref() {
    // Set Language from Preference instead of Browser.

    const data = this.dataService.userPrefenceData;
    const userData = data.filter((elem) => elem.Name == GeneralUserPreferences);
    if (this.dataService.orgRole.toLowerCase() !== "none") {
      if (userData.length == 0) {
        const lang = this.userService
          .getUserPreferences(GeneralUserPreferences)
          .subscribe((response) => {
            this.dataService.userPrefenceData.push({
              Name: GeneralUserPreferences,
              Settings: response.Settings,
            });
            this.setPreferenceData(response);
            lang.unsubscribe();
          });
      } else {
        this.setPreferenceData(userData[0]);
      }
    }
  }

  setPreferenceData(response) {
    if (response.Settings && response.Settings.length > 0) {
      if (response.Settings[2] && response.Settings[2].Name === UserLanguage) {
        const langPref = JSON.parse(response.Settings[2].Value);
        this.translate.use(langPref.locale);
        localStorage.setItem("locale", langPref.locale);
        localStorage.setItem("countryCode", langPref.countryCode);
      }
      if (
        response.Settings[3] &&
        response.Settings[3].Name === UserDateFormat
      ) {
        this.dataService.userDateFormat = response.Settings[3].Value;
        // settings for date fomate in saturn date picker
        localStorage.setItem("dateFormat", response.Settings[3].Value);
      }
      if (
        response.Settings[4] &&
        response.Settings[4].Name === UserTimeFormat
      ) {
        this.dataService.userDateFormat =
          this.dataService.userDateFormat + ", " + response.Settings[4].Value;
      }
    }
  }

  ngOnInit() {
    this.dataService.showLoadingLoader = true;
    this.dataService.showLoading = true;
    this.preventBackspaceNav();
    let eulaMsgAcceptance = localStorage.getItem("eula-msg-acceptance");
    if (eulaMsgAcceptance) {
      localStorage.removeItem("eula-msg-acceptance");
    }
    let cookieMsgAcceptance = localStorage.getItem("cookie-msg-acceptance");
    if (cookieMsgAcceptance) {
      localStorage.removeItem("cookie-msg-acceptance");
    }
    this.listenTabChange();
    this.initListenChange();
    this.initListenChangesOfQuickEstimator();
    let code = null;
    let state = null;
    code = this.getURLParams("code");
    this.checkCookie();

    // --- for parts expert intgn---starts----
    const external = this.getURLParams("external");
    const sourceType = this.getURLParams("sourceType");
    if (external || sourceType == "External") {
      this.handleExternalSource();
    } else if (sourceType == "Internal") {
      // --- for parts expert intgn---ends----
      this.handleInternalSource();
    }
    if (!environment.devModeEnabled) {
      this.handleProdMode(code, state);
    } else {
      // For debugging purpose, bypass CWS login
      this.userService.getPermissions(true).subscribe(
        (userdetails) => {
          this.dp = this.dataService.displayName;
          this.dataService.showLoadingLoader = false;
          this.dataService.showLoading = false;
          if (
            this.dataService.orgRole.toLowerCase() == "none" &&
            this.dataService.isDealer == true
          ) {
            this.router.navigateByUrl("/newuser");
            this.newUsercheckFlag = false;
          } else {
            this.newUsercheckFlag = true;
            this.populateDataLayer();
            this.setOneTrust();
            this.OnAuthorize("userdetails");
            this.initializeSelectionValues();
            this.fetchAlert();
          }
          this.isUserOrgIntermediate = this.dataService.isUserOrgIntermediate;
          this.isUserWgRoleReadOnly =
            this.dataService.defaultWGRole === WGRole.ReadOnly ? true : false;
        },
        (err) => {
          this.onLoginFailure(err);
        }
      );
    }

    this.loadNotificationInIntervals();
    this.checkIfBannerRequired();
    this.setDefaultLang();
  }

  handleInternalSource() {
    // URL for access level request
    const requestType = this.getURLParams("requestType");
    if (requestType == "UserElevation") {
      const RequestId = this.getURLParams("RequestId");
      if (RequestId) {
        if (sessionStorage.getItem("request_Id")) {
          sessionStorage.removeItem("request_Id");
        }
        sessionStorage.setItem("request_Id", RequestId);
      }
    }
    // URL for QE region settings
    else if (requestType == "RegionSetting") {
      const RequestId = this.getURLParams("RequestId");
      if (RequestId) {
        if (sessionStorage.getItem("requestId")) {
          sessionStorage.removeItem("requestId");
        }
        sessionStorage.setItem("requestId", RequestId);
      }
    }
  }

  handleExternalSource() {
    const builderId = this.getURLParams("builderId");
    if (builderId) {
      if (sessionStorage.getItem("builder_id")) {
        sessionStorage.removeItem("builder_id");
      }
      sessionStorage.setItem("builder_id", builderId);
    }
    const sourceOfData = this.getURLParams("sourceOfData");
    if (sourceOfData) {
      if (sessionStorage.getItem("sourceOf_Data")) {
        sessionStorage.removeItem("sourceOf_Data");
      }
      sessionStorage.setItem("sourceOf_Data", sourceOfData);
    }
    const estimateDetails = {
      sourceOfData: this.getURLParams("sourceOfData"),
      catCertifiedRebuild: this.getURLParams("catCertifiedRebuild"),
      estimateName: this.getURLParams("estimateName"),
      startUsage: this.getURLParams("startUsage"),
      endUsage: this.getURLParams("endUsage"),
      advancedGrouping: this.getURLParams("advancedGrouping"),
      includeNonServiceableParts: this.getURLParams(
        "includeNonServiceableParts"
      ),
      includeZeroReplacementParts: this.getURLParams(
        "includeZeroReplacementParts"
      ),
      estimateCurrency: this.getURLParams("estimateCurrency"),
      prefix: this.getURLParams("prefix"),
      model: this.getURLParams("model"),
      ccrType: this.getURLParams("ccrType"),
    };
    if (estimateDetails && (builderId || sourceOfData)) {
      this.setDetailsInSessionStorage(estimateDetails);
    }
  }

  handleProdMode(code, state) {
    if (code !== null && code !== undefined) {
      this.authService
        .getAccessTokenFromAuthServer(code)
        .pipe(retry(2))
        .subscribe(
          (res) => {
            this.dataService.showLoadingLoader = false;
            this.dataService.showLoading = false;
            this.OnAuthorize(res);
            if (
              this.dataService.orgRole.toLowerCase() == "none" &&
              this.dataService.isDealer == true
            ) {
              this.router.navigateByUrl("/newuser");
              this.newUsercheckFlag = false;
            } else {
              this.newUsercheckFlag = true;

              this.populateDataLayer();
              this.setOneTrust();
              this.initializeSelectionValues();
              this.fetchAlert();
            }
            this.isUserOrgIntermediate = this.dataService.isUserOrgIntermediate;
          },
          (err) => {
            // Try Re-Logging incase the login is failed
            // if (err.status !== 403) {
            // this.authService.initLogin();
            this.onLoginFailure(err);
          }
        );
    } else if (this.authService.isAuthenticated()) {
      // If Already Logged IN
      this.userService.getPermissions(true).subscribe(
        (userdetails) => {
          this.dp = this.dataService.displayName;
          this.dataService.showLoadingLoader = false;
          this.dataService.showLoading = false;
          if (
            this.dataService.orgRole.toLowerCase() == "none" &&
            this.dataService.isDealer == true
          ) {
            this.router.navigateByUrl("/newuser");
            this.newUsercheckFlag = false;
          } else {
            this.newUsercheckFlag = true;

            this.populateDataLayer();
            this.setOneTrust();
            this.OnAuthorize(userdetails);
            this.initializeSelectionValues();
            this.fetchAlert();
            this.isUserOrgIntermediate = this.dataService.isUserOrgIntermediate;
            this.isUserWgRoleReadOnly =
              this.dataService.defaultWGRole === WGRole.ReadOnly ? true : false;
          }
        },
        (err) => {
          this.onLoginFailure(err);
        }
      );
    } else {
      this.authService.initLogin();
    }
  }

  listenTabChange() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf("/parts-expert") > -1) {
          this.hideHeaderTabs = true;
          this.hideRightSideHeaderTabs = true;
        } else if (
          event.url.indexOf("/estimate/estimateDetails") > -1 ||
          event.url.indexOf("/MPestimate/estimateDetails") > -1
        ) {
          this.hideHeaderTabs = true;
          this.hideRightSideHeaderTabs = false;
        } else if (!(event.urlAfterRedirects.indexOf("/page-not-found") > -1)) {
          this.hideHeaderTabs = false;
          this.hideRightSideHeaderTabs = false;
        } else {
          this.hideHeaderTabs = true;
          this.hideRightSideHeaderTabs = true;
          this.showFooter = false;
        }
        this.handleNotificationIcon(event);
      }
      if (event instanceof NavigationStart) {
        if (
          event.url.indexOf("/privacy/eula") > -1 ||
          event.url.indexOf("/privacy/policy") > -1
        ) {
          this.hideNavBarBand = true;
          this.hideHeaderTabs = true;
          this.hideRightSideHeaderTabs = true;
          this.showFooter = false;
          this.showBanner = false;
          document
            .getElementById("removelay1")
            ?.classList.add("visiblity-hidden");
          document
            .getElementById("loading-bar")
            ?.classList.add("visiblity-hidden");
        }
      }
    });
  }

  handleNotificationIcon(event) {
    if (
      event.url.indexOf("admin") !== -1 ||
      event.url.indexOf("quick-estimator") !== -1
    ) {
      this.isAdminClicked = true;
      if (event.url.indexOf("quick-estimator") !== -1) {
        this.showBellIcon = false;
      }
    } else {
      this.isAdminClicked = false;
    }

    if (
      this.dataService &&
      this.dataService.cwsId &&
      event.url !== this.currentUrl
    ) {
      this.populateDataLayer();
    }
    if (
      (window as any).OneTrust &&
      !(window as any).OneTrust.IsAlertBoxClosed()
    ) {
      this.checkOneTrust();
    }
  }

  loadNotificationInIntervals() {
    setTimeout(() => {
      if (this.dataService.isUserOrgAdmin) {
        this.loadNotifications();
        this.getNotificationData();
        interval(
          1000 * 60 * 2 * this.dataService.notificationTimings
        ).subscribe(() => {
          this.getNotificationData();
        });
      }
    }, 20000);
  }

  checkIfBannerRequired() {
    const isBannerClosed = sessionStorage.getItem("isBannerClosed");
    this.showBanner = isBannerClosed === "true" ? false : true;
    this.fetchAlertsInIntervals();
  }

  checkForCookiesEnabledOrDisabled() {
    if ((window as any).OnetrustActiveGroups) {
      this.currentActiveGroups = (window as any).OnetrustActiveGroups;

      if ((window as any).OnetrustActiveGroups.indexOf("C0002") > -1) {
        this.appInsights.AppInsights.core.getCookieMgr().setEnabled(true);
      } else if (
        this.checkForPerfCookies((window as any).OnetrustActiveGroups)
      ) {
        this.appInsights.AppInsights.core.getCookieMgr().setEnabled(false);
      }
    }
  }
  ngAfterViewChecked() {
    this.checkActive();
    this.checkForOneTrustObject();
  }

  preventBackspaceNav() {
    window.onkeydown = (e) => {
      if (e.keyCode === 8 && !this.isInput(e.target)) {
        e.preventDefault();
      }
    };
  }

  isInput(element) {
    const tag = element.tagName.toLowerCase();
    if (
      tag !== "input" &&
      tag !== "textarea" &&
      !element.classList.contains("ql-editor")
    ) {
      return false;
    }

    if (
      tag == "div" &&
      !element.type &&
      element.classList.contains("ql-editor")
    ) {
      return true;
    }
    if (element.getAttribute("type")) {
      const type = element.getAttribute("type").toLowerCase();
      return (
        type === "text" ||
        type === "number" ||
        type === "date" ||
        type === "email" ||
        type === "password" ||
        type === "search" ||
        type === "tel" ||
        type === "time" ||
        type === "url" ||
        type === "week" ||
        type === "textarea"
      );
    } else if (
      element.type === "text" ||
      element.type === "number" ||
      element.type === "date" ||
      element.type === "email" ||
      element.type === "password" ||
      element.type === "search" ||
      element.type === "tel" ||
      element.type === "time" ||
      element.type === "url" ||
      element.type === "week"
    ) {
      return true;
    } else if (element.getAttribute("id") == "message-textarea") {
      return true;
    }
  }

  initListenChange() {
    this.listenChange.eventEmitListener$.subscribe((formContent) => {
      if (formContent && formContent.createForm) {
        this.checkCreateEstimateForm(formContent);
      }
      if (formContent) {
        this.checkTravelForm(formContent);
        this.checkGeneralForm(formContent);
        this.checkestimateSettingsForm(formContent);
        this.checkPerdiemForm(formContent);
        this.checkProfileForm(formContent);
        this.checkPartsForm(formContent);
        this.checkVariabilityForm(formContent);
        this.checkPriceEscalationForm(formContent);
        this.checkForFreTabChange(formContent);
      }
      if (formContent && formContent.adjustmentFormValueChange) {
        this.adjustmentFormvalueChange = formContent.adjustmentFormValueChange;
      }
      if (formContent && formContent.adjustmentFormValueChange === false) {
        this.adjustmentFormvalueChange = false;
      }
      if (formContent && formContent.valueChanged) {
        this.valueChanged = formContent.valueChanged;
      }
      if (formContent.langChange) {
        this.translate.use(formContent.useLang);
      }
      if (formContent && formContent.requestStatusChanged) {
        this.remove(formContent.requestId);
      }
      if (formContent && formContent.alertListUpdated) {
        this.updateAlerts(formContent.alertListUpdated);
      }
    });

    this.listenChange.formChangesObservable.subscribe((formObject) => {
      if (formObject && formObject.isFormSubmitted) {
        const dataLayerFormVariable = {
          event: "formSubmitted",
          pagePath: this.router.url,
          formName: formObject.formValue.formName,
          formContent: formObject.formValue.formContent,
          formContent2: formObject.formValue.formContent2,
          formStatus: formObject.formValue.formStatus,
          formFieldCausingError: null,
          formLocation:
            formObject.formValue.formLocation &&
            formObject.formValue.formLocation !== null
              ? formObject.formValue.formLocation
              : null,
        };

        (window as any).dataLayer.push(dataLayerFormVariable);
      }
    });
  }

  checkProfileForm(formContent) {
    if (formContent && formContent.profileChanged) {
      this.profileValueChanged = formContent.profileChanged;
    }
    if (formContent && formContent.profileChanged === false) {
      this.profileValueChanged = false;
    }
  }

  checkGeneralForm(formContent) {
    if (formContent.general) {
      this.generalChange = true;
    } else if (formContent.general === false) {
      this.generalChange = false;
    }
  }

  checkPartsForm(formContent) {
    if (formContent.parts) {
      this.partsChange = true;
    } else if (formContent.parts === false) {
      this.partsChange = false;
    }
  }

  checkestimateSettingsForm(formContent) {
    if (formContent.estimateSettings) {
      this.estimateSettingsChanged = true;
    } else if (formContent.estimateSettings === false) {
      this.estimateSettingsChanged = false;
    }
  }
  checkPerdiemForm(formContent) {
    if (formContent.perdiemChanged) {
      this.perdiemChange = true;
    } else if (formContent.perdiemChanged === false) {
      this.perdiemChange = false;
    }
  }

  checkPriceEscalationForm(formContent) {
    if (formContent.priceEscalation) {
      this.priceEscalationChange = true;
    } else if (formContent.priceEscalation === false) {
      this.priceEscalationChange = false;
    }
  }

  checkTravelForm(formContent) {
    if (formContent && formContent.travelForm) {
      this.travelChange = true;
    } else if (formContent && formContent.travelForm === false) {
      this.travelChange = false;
    }
  }

  checkCreateEstimateForm(formContent) {
    this.formChanged = false;
    Object.keys(formContent.createForm).forEach((control) => {
      if (formContent.createForm[control]) {
        if (control === "engineType") {
          if (formContent.createForm[control] !== this.machine) {
            this.formChanged = true;
          }
        } else {
          this.formChanged = true;
        }
      }
    });
  }

  checkVariabilityForm(formContent) {
    if (formContent.variabilty) {
      this.underCarriageChanged = true;
    } else if (formContent.variabilty === false) {
      this.underCarriageChanged = false;
    }
  }

  initListenChangesOfQuickEstimator() {
    this.estimateService.currentData.subscribe((formContent: any) => {
      this.quickEstimatorChanged = false;
      if (formContent !== "") {
        if (
          formContent.estimateName !== "" ||
          formContent.startUsage !== "0" ||
          formContent.endUsage !== ""
        ) {
          this.quickEstimatorChanged = true;
        } else {
          this.quickEstimatorChanged = false;
        }
      }
    });

    this.quickEstimatorService.currentData.subscribe((formContent: any) => {
      this.quickEstimatorChanged = false;
      if (formContent !== "") {
        if (
          formContent.estimateName !== "" ||
          formContent.startUsage !== "" ||
          formContent.endUsage !== ""
        ) {
          this.quickEstimatorChanged = true;
        } else {
          this.quickEstimatorChanged = false;
        }
      }
    });
  }

  private getURLParams(param) {
    const results = new RegExp("[?&]" + param + "=([^&#]*)").exec(
      window.location.href
    );
    if (results) {
      return decodeURI(results[1]) || 0;
    }
    return null;
  }
  onLoginFailure(err) {
    const element2 = document.getElementById("removelay2");
    element2.classList.remove("overlay");
    const element3 = document.getElementById("removelay3");
    element3.classList.remove("overlay");
    if (err.status !== 403 && err.status !== 401 && err.status !== 400) {
      this.router.navigateByUrl("/general-exception");
    }
  }
  OnAuthorize(userDetails: any) {
    this.setLocalizationPref();
    this.dp = this.dataService.displayName;
    const url = window.location.href;
    if (this.dataService.canAccess) {
      if (this.dataService.canAccessCalc) {
        this.canAccessCalc(url);
      } else {
        if (
          sessionStorage.getItem("builder_id") ||
          sessionStorage.getItem("sourceOf_Data")
        ) {
          this.router.navigateByUrl("/parts-expert");
          setTimeout(() => {
            this.showNoAccessMsg();
          }, 1000);
        } else if (
          sessionStorage.getItem("request_Id") ||
          sessionStorage.getItem("requestId")
        ) {
          this.showNoAccessMsgForElevatedAccess();
        }
        this.showMsgFor404Or401();
      }
    } else {
      this.informNoAccessToUser();
      this.router.navigateByUrl("/401");
    }
  }

  canAccessCalc(url) {
    if (
      sessionStorage.getItem("builder_id") ||
      sessionStorage.getItem("sourceOf_Data")
    ) {
      this.checkForBuilderIdOrSourceofData();
    } else if (
      sessionStorage.getItem("request_Id") ||
      sessionStorage.getItem("requestId")
    ) {
      this.checkIfOrgAdminAndNavigate();
    } else if (url.indexOf("code") > -1) {
      this.router.navigateByUrl("/home");
    } else if (window.location.pathname) {
      this.showMsgFor404Or401();
    }
  }

  checkForBuilderIdOrSourceofData() {
    if (sessionStorage.getItem("builder_id")) {
      this.router.navigateByUrl("/landing-page");
      setTimeout(() => {
        this.handlePartsExpert(true);
      }, 1000);
    } else if (sessionStorage.getItem("sourceOf_Data")) {
      this.handlePartsExpert(true);
    }
  }

  checkIfOrgAdminAndNavigate() {
    if (this.dataService.isUserOrgAdmin) {
      this.router.navigateByUrl("/admin/user-requests");
    } else {
      this.showNoAccessMsgForElevatedAccess();
    }
  }

  showMsgFor404Or401() {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/general-exception" ||
      window.location.pathname === "/401"
    ) {
      this.router.navigateByUrl("/home");
    } else {
      this.router.navigateByUrl(window.location.pathname);
    }
  }

  informNoAccessToUser() {
    if (
      sessionStorage.getItem("builder_id") ||
      sessionStorage.getItem("sourceOf_Data")
    ) {
      this.router.navigateByUrl("/parts-expert");
      setTimeout(() => {
        this.showNoAccessMsg();
      }, 1000);
    } else if (sessionStorage.getItem("request_Id")) {
      this.showNoAccessMsgForElevatedAccess();
    } else if (sessionStorage.getItem("requestId")) {
      this.showNoAccessMsgForElevatedAccess();
    }
  }

  onLogout() {
    sessionStorage.removeItem("isBannerClosed");
    this.authService.logout();
  }

  checkActive() {
    if (
      window.location.pathname === "/estimate" ||
      Object.values(window.location.pathname.split("/"))[1] === "estimate"
    ) {
      this.activeTabname = "nav-estimate-tab";
      this.changeTabStyle();
      if (document.getElementById("nav-estimate-tab")) {
        document.getElementById("nav-estimate-tab").classList.add("active");
      }
    } else if (
      window.location.pathname === "/quick-estimator" ||
      window.location.pathname.startsWith("/quick-estimator/") ||
      window.location.pathname ===
        "/quick-estimator/admin/regional-price-upload"
    ) {
      this.activeTabname = "nav-quick-estimate-tab";
      this.changeTabStyle();
      if (document.getElementById("nav-quick-estimate-tab")) {
        document
          .getElementById("nav-quick-estimate-tab")
          .classList.add("active");
      }
    } else {
      this.checkActive2();
    }
  }

  checkActive1() {
    if (window.location.pathname === "/home") {
      this.activeTabname = "nav-home-tab";
      this.changeTabStyle();
      if (document.getElementById("nav-home-tab")) {
        document.getElementById("nav-home-tab").classList.add("active");
      }
    } else if (window.location.pathname === "/profile") {
      this.removeActiveClass();
    } else if (window.location.pathname === "/aboutus") {
      this.removeActiveClass();
    } else if (window.location.pathname === "/contactus") {
      this.removeActiveClass();
    }
  }

  checkActive2() {
    if (window.location.pathname === "/settings/labor") {
      this.setActiveForSettingsTab();
    } else if (window.location.pathname === "/global-settings/labor") {
      this.setActiveForSettingsTab();
    } else if (window.location.pathname === "/quickestimator-settings/parts") {
      this.setActiveForSettingsTab();
    } else if (this.checkForAdminTabNavigation()) {
      this.activeTabname = "nav-admin-tab";
      this.changeTabStyle();
      if (document.getElementById("nav-admin-tab")) {
        document.getElementById("nav-admin-tab").classList.add("active");
      }
    } else {
      this.checkActive1();
    }
  }

  checkForAdminTabNavigation() {
    if (
      window.location.pathname === "/admin" ||
      window.location.pathname === "/admin/all-organizations" ||
      window.location.pathname === "/admin/my-organizations" ||
      window.location.pathname === "/admin/my-workgroups" ||
      window.location.pathname === "/admin/user-requests"
    ) {
      return true;
    } else {
      return false;
    }
  }

  setActiveForSettingsTab() {
    this.activeTabname = "nav-settings-tab";
    this.changeTabStyle();
    if (document.getElementById("nav-settings-tab")) {
      document.getElementById("nav-settings-tab").classList.add("active");
    }
  }

  removeActiveClass() {
    if (document.getElementById(this.activeTabname)) {
      document.getElementById(this.activeTabname).classList.remove("active");
    }
  }

  openModalWithTemplate = (content: any) => {
    this.modal.openModal(content, {
      height: "570px",
      width: "650px",
      hasBackdrop: true,
    });
  };

  // Tab change to handle new settings tabs(Global and workgroup)
  tabChange2(id) {
    this.isAdminClicked = false;
    this.showBellIcon = true;
    const tabName = id;
    this.changeTabStyle();
    const tab = document.getElementById("nav-settings-tab");
    if (
      tabName !== "menu-about-us" &&
      tabName !== "menu-profile" &&
      tabName !== "menu-contactus"
    ) {
      tab.classList.add("active");
    }
    if (tabName === "nav-wgsettings-tab") {
      this.wgService.isQuickEstimatorSettings = false;
      this.router.navigateByUrl("/settings");
    } else if (tabName === "nav-globalsettings-tab") {
      this.router.navigateByUrl("/global-settings");
    } else if (tabName === "nav-quickEstimatorSettings-tab") {
      this.wgService.isQuickEstimatorSettings = true;
      this.router.navigateByUrl("/quickestimator-settings");
    }
    this.valueChanged = false;
    this.adjustmentFormvalueChange = false;
    this.formChanged = false;
    this.estimateSettingsChanged = false;
    this.partsChange = false;
    this.travelChange = false;
    this.priceEscalationChange = false;
    this.generalChange = false;
    this.underCarriageChanged = false;
    this.isEdit = true;
    this.freChanged = false;
  }

  tabChange(event: any) {
    this.isAdminClicked = false;
    this.showBellIcon = true;
    const tabName = event.target.id;
    this.changeTabStyle();
    const tab = document.getElementById(tabName);
    if (
      tabName !== "menu-about-us" &&
      tabName !== "menu-profile" &&
      tabName !== "menu-contactus"
    ) {
      tab.classList.add("active");
    }
    if (tabName === "nav-home-tab") {
      this.router.navigateByUrl("/home");
    } else if (tabName === "nav-estimate-tab") {
      this.router.navigateByUrl("/estimate");
    } else if (tabName === "nav-template-tab") {
      this.router.navigateByUrl("/templates");
    } else if (tabName === "nav-quick-estimate-tab") {
      this.router.navigateByUrl("/quick-estimator");
    } else if (tabName === "nav-settings-tab") {
      this.router.navigateByUrl("/settings");
    } else if (tabName === "nav-admin-tab") {
      this.checkForAdminAccessAndRoute();
    } else if (tabName === "menu-profile") {
      this.router.navigateByUrl("/profile");
    } else if (tabName === "menu-about-us") {
      this.router.navigateByUrl("/aboutus");
    } else if (tabName === "menu-contactus") {
      this.router.navigateByUrl("/contactus");
    }
    this.valueChanged = false;
    this.adjustmentFormvalueChange = false;
    this.formChanged = false;
    this.estimateSettingsChanged = false;
    this.partsChange = false;
    this.travelChange = false;
    this.priceEscalationChange = false;
    this.generalChange = false;
    this.underCarriageChanged = false;
    this.isEdit = true;
    this.freChanged = false;
  }

  checkForAdminAccessAndRoute() {
    if (this.dataService.systemRole === SysRole.Admin) {
      this.router.navigate(["admin/all-organizations"]);
    } else {
      if (this.dataService.isUserOrgAdmin) {
        this.router.navigate(["admin/my-organizations"]);
      } else if (this.dataService.isUserWGAdmin) {
        this.router.navigate(["admin/my-workgroups"]);
      } else {
        this.router.navigate(["admin/user-requests"]);
      }
    }
  }

  changeTabStyle() {
    const elements = document.getElementsByClassName("tab-menu");
    _.forEach(elements, function (item) {
      item.className = item.className.replace(/\bactive\b/g, "");
    });
  }

  getActiveClassIndex(classList) {
    for (let i = 0; i < classList.length; i++) {
      if (classList[i] === "active") {
        return i;
      }
    }
    return null;
  }

  checkTabChange(event, flag?) {
    this.checkForEdit();
    if (this.router.url === "/estimate/estimateDetails" && this.formChanged) {
      this.showConfirm(event, flag);
    } else if (
      (this.router.url === "/quick-estimator" ||
        this.router.url.startsWith("/quick-estimator/")) &&
      this.quickEstimatorChanged &&
      event.target.id !== "nav-quick-estimate-tab"
    ) {
      this.showConfirm(event, flag);
    } else {
      this.checkTabChange2(event, flag);
    }
  }

  checkTabChange2(event, flag?) {
    if (
      (this.router.url === "/settings/general" ||
        this.router.url === "/global-settings/general" ||
        this.router.url === "/quickestimator-settings/general") &&
      this.generalChange
    ) {
      this.save(event, flag);
    } else if (
      this.router.url === "/settings/estimate-settings" &&
      this.estimateSettingsChanged
    ) {
      this.save(event, flag);
    } else if (
      (this.router.url === "/settings/travel" ||
        this.router.url === "/global-settings/travel") &&
      this.travelChange
    ) {
      this.save(event, flag);
    } else {
      this.checkTabChange3(event, flag);
    }
  }

  checkTabChange3(event, flag) {
    if (
      (this.router.url === "/settings/perdiem" ||
        this.router.url === "/global-settings/perdiem") &&
      this.perdiemChange
    ) {
      this.save(event, flag);
    } else if (
      this.router.url === "/settings/price-escalation" &&
      this.priceEscalationChange
    ) {
      this.save(event, flag);
    } else if (
      (this.router.url === "/settings/parts" ||
        this.router.url === "/quickestimator-settings/parts") &&
      this.partsChange
    ) {
      this.save(event, flag);
    } else if (
      this.router.url === "/settings/flat-rate-exchange" &&
      this.freChanged
    ) {
      this.save(event, flag);
    } else {
      this.checkTabChange4(event, flag);
    }
  }

  checkTabChange4(event, flag) {
    if (
      (this.router.url === "/settings/adjustments" ||
        this.router.url === "/global-settings/adjustments") &&
      this.adjustmentFormvalueChange
    ) {
      this.save(event, flag);
    } else if (
      this.router.url.split(";")[0] === "/estimate/pricing" &&
      this.isEdit &&
      this.valueChanged
    ) {
      this.showConfirm(event, flag, true);
    } else if (this.router.url === "/profile" && this.profileValueChanged) {
      this.save(event, flag);
    } else {
      this.checkTabChange5(event, flag);
    }
  }

  checkTabChange5(event, flag) {
    if (
      this.router.url === "/global-settings/variabilty" &&
      this.underCarriageChanged
    ) {
      this.save(event, flag);
    } else if (event.target.id == "menu-notification") {
      this.openModalWithTemplate(flag);
      this.loadNotifications();
    } else {
      if (flag) {
        this.tabChange2(flag);
      } else {
        this.tabChange(event);
      }
    }
  }

  checkForEdit() {
    if (
      this.router.url.split(";")[3] &&
      this.router.url.split(";")[3] === "action=view"
    ) {
      this.isEdit = false;
    } else if (
      this.router.url.split(";")[3] &&
      this.router.url.split(";")[3] === "action=edit"
    ) {
      this.isEdit = true;
    }
  }

  save(event, flag?) {
    const modalRef = this.modalService.open(ConfirmAlertComponent, {
      centered: true,
      windowClass: "confirm-modal",
    });
    modalRef.componentInstance.alertMessage = this.translate.instant(
      "discardChangesConfirmMsg"
    );
    modalRef.componentInstance.delete = true;
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant("lblcancel"),
        cssClass: ["btn btn-outline-secondary btn-width btn-mr"],
        handler: (modal) => {
          modal.close(false);
        },
      },
      {
        text: this.translate.instant("discard"),
        cssClass: ["btn btn-warning btn-width "],
        handler: (modal) => {
          if (this.router.url == "/profile") {
            this.profileValueChanged = false;
            this.dataService.discardProfileChanges();
            modal.close(false);
            this.checkTabChange4(event, flag);
          } else {
            this.closeModalAndChangeTab(event, modal, flag);
          }
        },
      },
    ];
  }

  showConfirm(event, flag?, isPricing?: boolean) {
    const modalRef = this.modalService.open(ConfirmAlertComponent, {
      centered: true,
      windowClass: "confirm-modal",
    });
    if (this.quickEstimatorChanged) {
      modalRef.componentInstance.alertMessage = this.translate.instant(
        "estimateConfirmMessage"
      );
    } else if (isPricing) {
      modalRef.componentInstance.alertMessage = this.translate.instant(
        "estimateConfirmChangeMessage"
      );
    } else {
      modalRef.componentInstance.alertMessage = this.translate.instant(
        "estimateConfirmMessage"
      );
    }
    modalRef.componentInstance.delete = true;
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant("lblcancel"),
        cssClass: ["cat-btn-secondary-outline back-button btn-mr"],
        handler: (modal) => {
          modal.close(false);
        },
      },
      {
        text: this.translate.instant("discard"),
        cssClass: ["cat-btn-primary "],
        handler: (modal) => {
          if (this.quickEstimatorChanged) {
            this.quickEstimatorService.changeData("");
            this.quickEstimatorService.changeProductDetailsFormData("");
          }

          this.closeModalAndChangeTab(event, modal, flag);
        },
      },
    ];
  }

  closeModalAndChangeTab(event, modal, flag) {
    modal.close(false);
    if (flag) {
      this.tabChange2(flag);
    } else {
      this.tabChange(event);
    }
  }

  displayPrivacyPolicy() {
    this.modal.openModal(PrivacyPolicyComponent, {
      backdropClass: "cc-modal-full-window-backdrop",
      panelClass: "cc-modal-full-window",
    });
  }

  wgValueChange(event) {
    const request = {
      Name: DefaultUserWGPreferences,
      Settings: [
        { Name: DefaultUserWGPreferences, Value: event.value.WorkGroupId },
      ],
    };
    this.userService.saveUserPreferences(request).pipe(take(1)).subscribe();
    this.dataService.defaultOrgWG = event.value;
    this.subjectService.sendMessage(event.value, "wgChangeListener");
    this.dataService.defaultWGRole = event.value.Role;

    this.isUserWgRoleReadOnly =
      this.dataService.defaultWGRole === WGRole.ReadOnly ? true : false;
    this.subjectService.sendMessage(event.value.Role, "defaultWGRoleChanged");
    const data = this.dataService.userPrefenceData;
    const userData = data.filter((elem) => elem.Name == request.Name);
    if (userData.length != 0) {
      const index = this.dataService.userPrefenceData.indexOf(userData[0]);
      this.dataService.userPrefenceData.splice(index, 1);
    }
    this.dataService.userPrefenceData.push({
      Name: request.Name,
      Settings: request.Settings,
    });
    if (window.location.pathname) {
      this.router.navigate([window.location.pathname], {
        state: { action: "reload" },
      });
    } else {
      this.router.navigateByUrl("/home");
    }
  }
  /* Currently we are not providing capability to select multiple organization per user */
  organizationChange(event) {
    this.dataService.defaultOrg = event.value;
    this.dataService.orgName = event.value.OrganizationName;
    this.dataService.workgroups = event.value.Workgroups;
    if (event.value.Workgroups.length > 0) {
      this.dataService.defaultOrgWG = event.value.Workgroups[0];
    }
  }

  checkCookie() {
    const element1 = document.getElementById("removelay1");
    element1.classList.remove("z-ind");
    const element2 = document.getElementById("removelay2");
    element2.classList.remove("overlay");
    const element3 = document.getElementById("removelay3");
    element3.classList.remove("overlay");
  }

  navigateToHomePage() {
    this.listenChange.sendMessage(
      { saveAndExitEstimate: true },
      "eventEmitListen"
    );
  }
  passPopovervariable(p1) {
    this.popoverVariable = p1;
  }
  closePopover() {
    this.popoverVariable.close();
  }
  handlePartsExpert(hasAccess) {
    this.filterWorkGroupDropDown();
    if (this.workGroupDdlData.length > 0) {
      this.openWorkGroupPopUp();
    } else {
      this.showNoAccessMsg();
    }
  }
  openWorkGroupPopUp() {
    const modalRef = this.dialog.open(PartsExpertWorkgroupComponent, {
      panelClass: "custom-modalbox",
    });
    modalRef.componentInstance.alertMessage = this.translate.instant(
      "parts-expert-selection"
    );
    modalRef.componentInstance.workGroupList = this.workGroupDdlData;
    modalRef.disableClose = true;
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant("confirmOk"),
        cssClass: ["cat-btn-primary"],
        handler: (modal) => {
          modal.close(false);
        },
      },
    ];

    modalRef.afterClosed().subscribe((data) => {
      this.router.navigate(["estimate/estimateDetails"], {
        state: { action: "reload" },
      });
    });
  }

  showNoAccessMsg() {
    const modalRef = this.modalService.open(ConfirmAlertComponent, {
      centered: true,
      windowClass: "confirm-modal",
      backdropClass: "confirm-modal-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.isMessage = true;
    modalRef.componentInstance.noCloseIcon = true;
    modalRef.componentInstance.notAuthorized = true;
    modalRef.componentInstance.alertMessage = this.translate.instant(
      "partsExpertNoWorkGroupMsg"
    );
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant("confirmOk"),
        cssClass: ["cat-btn-primary"],
        handler: (modal) => {
          modal.close(false);
          this.subjectService.sendMessage("ReadOnly", "defaultWGRoleChanged");
          this.router.navigateByUrl("/home");
        },
      },
    ];
  }
  filterWorkGroupDropDown() {
    const tempArray = this.dataService.workgroups;
    this.workGroupDdlData = tempArray.filter((el) => {
      return el.Role === WGRole.Admin || el.Role === WGRole.Advanced;
    });
  }
  checkOneTrust() {
    document.getElementById("onetrust-consent-sdk");
    function getCookie(name) {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length == 2) {
        return true;
      }
    }

    function reloadOTBanner() {
      const otConsentSdk = document.getElementById("onetrust-consent-sdk");
      if (otConsentSdk) {
        otConsentSdk.remove();
      }

      if ((window as any).OneTrust != null) {
        (window as any).OneTrust.Init();
        setTimeout(function () {
          (window as any).OneTrust.LoadBanner();
          const toggleDisplay = document.getElementsByClassName(
            "ot-sdk-show-settings"
          );

          for (let i = 0; i < toggleDisplay.length; i++) {
            (toggleDisplay[i] as any).onclick = function (event) {
              event.stopImmediatePropagation();
              (window as any).OneTrust.ToggleInfoDisplay();
            };
          }
        }, 1000);
      }
    }
    reloadOTBanner();
    if ((window as any).OneTrust != null) {
      if (this.checkConsentChanged()) {
        this.checkForCookiesEnabledOrDisabled();
      }
    }
  }
  checkConsentChanged(): boolean {
    const conditionA =
      this.currentActiveGroups.indexOf("C0002") > -1 &&
      !((window as any).OnetrustActiveGroups.indexOf("C0002") > -1);
    const conditionB =
      !(this.currentActiveGroups.indexOf("C0002") > -1) &&
      (window as any).OnetrustActiveGroups.indexOf("C0002") > -1;

    const conditionC =
      this.currentActiveGroups.indexOf("C0002") > -1 &&
      (window as any).OnetrustActiveGroups.indexOf("C0002") > -1;
    const conditionD =
      !(this.currentActiveGroups.indexOf("C0002") > -1) &&
      !((window as any).OnetrustActiveGroups.indexOf("C0002") > -1);
    if (conditionA || conditionB) {
      return true;
    } else if (conditionC || conditionD) {
      return false;
    }
    return false;
  }
  setOneTrust() {
    (window as any).dataLayer.push({
      event: "isLoginComplete",
      loggedIn: "true",
    });
  }

  checkForOneTrustObject() {
    if ((window as any).OneTrust) {
      (window as any).OneTrust.OnConsentChanged((e: any) => {
        if (this.checkConsentChanged()) {
          this.checkForCookiesEnabledOrDisabled();
        }
      });
    }
    if (!this.initialCheck && (window as any).OneTrust) {
      this.initialCheck = true;
      this.checkForCookiesEnabledOrDisabled();
      if (
        document.getElementById("ot-sdk-btn") &&
        document.getElementById("ot-sdk-btn").classList
      ) {
        document
          .getElementById("ot-sdk-btn")
          .classList.add("cursor-pointer-cookie");
      }
    }
  }

  showNoAccessMsgForElevatedAccess() {
    const modalRef = this.modalService.open(ConfirmAlertComponent, {
      centered: true,
      windowClass: "confirm-modal",
      backdropClass: "confirm-modal-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.isMessage = true;
    modalRef.componentInstance.noCloseIcon = true;
    modalRef.componentInstance.notAuthorized = true;
    modalRef.componentInstance.alertMessage = this.translate.instant(
      "elevatedAccess_noAccessMsg"
    );
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant("confirmOk"),
        cssClass: ["cat-btn-primary"],
        handler: (modal) => {
          modal.close(false);
          this.router.navigateByUrl("/home");
          sessionStorage.removeItem("request_Id");
        },
      },
    ];
  }

  loadNotifications() {
    this.userService.loadNotificationSettings().subscribe((response) => {
      if (response.NotificationSettings != null) {
        this.isButtonDisable = true;
        this.isElevRequestNotification =
          response?.NotificationSettings?.Settings?.AccessElevation?.PushMessage;
        this.isQERequestNotification =
          response?.NotificationSettings?.Settings?.RegionSetting?.PushMessage;
        if (this.dataService.isDealer == true) {
          this.isElevReqEmailUpdate =
            response?.NotificationSettings?.Settings?.AccessElevation?.Email;
        }
        this.isQEReqEmailUpdate =
          response?.NotificationSettings?.Settings?.RegionSetting?.Email;
        if (this.isElevRequestNotification || this.isQERequestNotification) {
          this.showNotificationIcon = true;
        } else {
          this.showNotificationIcon = false;
        }
      }
    });
  }

  saveNotifications(model) {
    const saveNotification = {
      NotificationSettings: {
        Settings: {
          AccessElevation: {
            PushMessage: this.isElevRequestNotification,
            Email: this.isElevReqEmailUpdate,
          },
          RegionSetting: {
            PushMessage: this.isQERequestNotification,
            Email: this.isQEReqEmailUpdate,
          },
        },
      },
    };

    this.userService
      .saveNotificationSettings(saveNotification)
      .subscribe((response) => {
        if (response.NotificationSettings != null) {
          this.isElevRequestNotification =
            response?.NotificationSettings?.Settings?.AccessElevation?.PushMessage;
          this.isQERequestNotification =
            response?.NotificationSettings?.Settings?.RegionSetting?.PushMessage;
          this.isElevReqEmailUpdate =
            response?.NotificationSettings?.Settings?.AccessElevation?.Email;
          this.isQEReqEmailUpdate =
            response?.NotificationSettings?.Settings?.RegionSetting?.Email;
        }
        if (this.isElevRequestNotification || this.isQERequestNotification) {
          this.showNotificationIcon = true;
        } else {
          this.showNotificationIcon = false;
        }
        model.close();
      });
  }

  markasRead() {
    this.notificationData.forEach((data) => {
      if (!data.Read) {
        data.Read = true;
      }
    });
    this.count = 0;
  }

  navigateToAdmin(data, res) {
    if (data.RequestType == "UserElevationAccess") {
      this.dataService.navigateToElevatedAccess = data.RequestItem;
    } else {
      this.dataService.navigateToQERequest = data.RequestItem;
    }
    if (this.router.url == "/admin/user-requests") {
      this.listenChange.sendMessage(
        { notificationClicked: true, data },
        "eventEmitListen"
      );
    } else {
      this.router.navigateByUrl("/admin/user-requests");
    }
    this.count = 0;
    this.notificationData.forEach((data) => {
      if (!data.Read) {
        this.count += 1;
      }
    });
  }

  initializeSelectionValues() {
    this.wgService.getWorkgroupSelectionValue().subscribe((data) => {
      this.wgService.setWgSelectionValues(data);
      this.dataService.selectionValues = JSON.parse(JSON.stringify(data));
    });
  }
  getNotificationData() {
    this.userService.loadNotificationData().subscribe((res) => {
      if (res.length > 0) {
        this.notificationData = res.filter(
          (diffDays) =>
            this.dateDifferencePipe.transform(
              diffDays.RequestItem.RequestedTime,
              "-1"
            ) <= 7
        );
        if (this.notificationData.length > 10) {
          this.notificationData = this.notificationData.slice(0, 10);
        }

        this.notificationData.forEach((data) => {
          if (!data.Read) {
            this.count += 1;
          }
        });
      } else {
        this.notificationData = null;
      }
    });
  }
  remove(elem: any) {
    // ALSO CALL A BACKEND API... TO REMOVE THEM .. THIS IS ONLY FRONT END
    this.notificationData = this.notificationData.filter(
      (xx) => xx.RequestItem.RequestId != elem
    );
    this.countData = this.notificationData.length;
  }

  checkForPerfCookies(activeGroups): boolean {
    const conditionC0001 = activeGroups.indexOf("C0001") > -1;
    const conditionC0003 = activeGroups.indexOf("C0003") > -1;
    const conditionC0004 = activeGroups.indexOf("C0004") > -1;
    const conditionC0002 = activeGroups.indexOf("C0002") > -1;

    if (
      (conditionC0001 || conditionC0003 || conditionC0004) &&
      !conditionC0002
    ) {
      return true;
    }
    return false;
  }
  deleteAppInsightsCookies() {
    this.appInsights.AppInsights.getCookieMgr().purge("ai_user");
    this.appInsights.AppInsights.getCookieMgr().purge("ai_session");
  }

  populateDataLayer() {
    if (this.dataService.cwsId && this.currentUrl !== this.router.url) {
      const templateURl = this.getTemplateName(this.router.url);
      let cwsObject;

      const hashCWS = sha256(this.dataService.cwsId).toString();
      if (!this.currentCwsObj && templateURl !== undefined) {
        cwsObject = {
          loginID: hashCWS,
          catRecId: this.dataService.CatRecId.toString(),
          siteId: "calculator.cat.com",
          ssoAffiliation:
            this.dataService.ssoAffilitation &&
            this.dataService.ssoAffilitation.toString() !== ""
              ? this.dataService.ssoAffilitation
              : null,
          templateName:
            templateURl === undefined || templateURl == "" ? null : templateURl,
        };
        (window as any).dataLayer.push(cwsObject);
        this.currentUrl = this.router.url;
        this.currentCwsObj = cwsObject;
      } else if (templateURl !== undefined) {
        this.templateUrlIsUndefined(templateURl, hashCWS, cwsObject);
      }
    }
  }

  templateUrlIsUndefined(templateURl, hashCWS, cwsObject) {
    let indexPresent = false;
    this.templateNames.forEach((template) => {
      if (
        (this.currentCwsObj as any).templateName.includes(template) &&
        templateURl.includes(template)
      ) {
        indexPresent = true;
        return;
      }
    });
    if (indexPresent) {
      cwsObject = {
        event: "spaContentChange",
        pagePath: this.router.url,
      };
      (window as any).dataLayer.push(cwsObject);
      this.currentUrl = this.router.url;
    } else {
      cwsObject = {
        loginID: hashCWS,
        catRecId: this.dataService.CatRecId.toString(),
        siteId: "calculator.cat.com",
        ssoAffiliation:
          this.dataService.ssoAffilitation &&
          this.dataService.ssoAffilitation.toString() !== ""
            ? this.dataService.ssoAffilitation
            : null,
        templateName:
          templateURl === undefined || templateURl == "" ? null : templateURl,
      };
      (window as any).dataLayer.push(cwsObject);
      this.currentUrl = this.router.url;
      this.currentCwsObj = cwsObject;
    }
  }

  getTemplateName(url) {
    let title = this.appRoutingService.getRouteTitle();
    if (this.router.url.includes("quickestimator-settings")) {
      title = title.replace("Workgroup Settings", "Quick Estimator Settings");
    }
    return title;
  }

  openWarning(messageText, actionObj, alertStyle, item) {
    if (item) {
      sessionStorage.setItem("alertId", item.UniqueId);
      this.alertAlreadyDisplayed = true;
    }
    document
      .getElementById("error-message-container-alert")
      .classList.remove("d-none");
    const configObj: any = {
      hostType: "inline",
      hostSelectorId: "error-message-container-alert",
      horizontalPosition: "center",
      verticalPosition: "top",
    };
    this.messageRef1 = this.messageBar.open(
      messageText,
      alertStyle,
      actionObj,
      configObj
    );
    this.messageRef1
      .afterClosed()
      .pipe(takeUntil(this.closeMessage))
      .subscribe((ref) => {
        document
          .getElementById("error-message-container-alert")
          .classList.add("d-none");
        this.closeMessage.next(true);
        this.closeMessage.complete();
        this.setFlags();
        this.isLivealert = false;
      });
  }

  setFlags() {
    if (this.bannerToUpdate) {
      this.triggerUpdatedBanner();
    } else {
      sessionStorage.setItem("isBannerClosed", "true");
      this.showBanner = false;
      this.alertAlreadyDisplayed = false;
    }
  }

  fetchAlert(isNotInitialCall?) {
    const msg = this.userService.alertMessage().subscribe((response) => {
      if (response && response.length !== 0) {
        this.alertmessage = response[0]?.Message;
        const erMsg = this.alertmessage;
        this.isLivealert = response[0]?.IsLive;
        const actionObj = [];
        this.wgService.isBannerAlign = true;
        let alertStyle;
        this.alertBorder = response[0]?.AlertType;
        if (response[0]?.AlertType == "Informational") {
          alertStyle = "info";
        } else if (response[0]?.AlertType == "DownTime") {
          alertStyle = "warning";
        } else if (response[0]?.AlertType == "Emergency") {
          alertStyle = "error";
        }
        if (isNotInitialCall) {
          this.handleAlert(erMsg, actionObj, alertStyle, response[0]);
        } else if (this.showBanner) {
          this.openWarning(erMsg, actionObj, alertStyle, response[0]);
        }
      } else if (response && response.length === 0) {
        this.removeExistingAlert();
      }
      msg.unsubscribe();
    });
  }

  fetchAlertsInIntervals() {
    interval(1000 * 60 * this.dataService.alertsApiCallInterval).subscribe(
      () => {
        this.fetchAlert(true);
      }
    );
  }

  handleAlert(messageText, actionObj, alertStyle, item) {
    if (this.showBanner && !this.alertAlreadyDisplayed) {
      this.openWarning(messageText, actionObj, alertStyle, item);
    } else if (!this.showBanner && !this.alertAlreadyDisplayed) {
      this.checkIfNewAlert(messageText, actionObj, alertStyle, item);
    } else if (this.showBanner && this.alertAlreadyDisplayed) {
      this.closeExistingAlert(messageText, actionObj, alertStyle, item);
    }
  }

  removeExistingAlert() {
    if (this.showBanner && this.alertAlreadyDisplayed) {
      if (this.messageRef1) {
        this.messageRef1.dismiss();
      }
    }
  }

  checkIfNewAlert(messageText, actionObj, alertStyle, item) {
    const id = sessionStorage.getItem("alertId");
    if (item.UniqueId !== id) {
      sessionStorage.setItem("isBannerClosed", "true");
      this.showBanner = true;
      setTimeout(() => {
        this.openWarning(messageText, actionObj, alertStyle, item);
      }, 100);
    }
  }

  closeExistingAlert(messageText, actionObj, alertStyle, item) {
    const id = sessionStorage.getItem("alertId");
    if (item.UniqueId.toString() !== id) {
      this.bannerToUpdate = true;
      this.updateBannerConfig = {
        text: messageText,
        actionObj,
        alertStyle,
        item,
      };
      this.removeExistingAlert();
    }
  }

  triggerUpdatedBanner() {
    this.openWarning(
      this.updateBannerConfig.text,
      this.updateBannerConfig.actionObj,
      this.updateBannerConfig.alertStyle,
      this.updateBannerConfig.item
    );
    this.bannerToUpdate = false;
  }

  updateAlerts(data) {
    if (data.item.length !== 0) {
      this.alertmessage = data.item[0]?.Message;
      const erMsg = this.alertmessage;
      this.isLivealert = data.item[0]?.IsLive;
      const actionObj = [];
      this.wgService.isBannerAlign = true;
      let alertStyle;
      this.alertBorder = data.item[0]?.AlertType;
      if (data.item[0]?.AlertType == "Informational") {
        alertStyle = "info";
      } else if (data.item[0]?.AlertType == "DownTime") {
        alertStyle = "warning";
      } else if (data.item[0]?.AlertType == "Emergency") {
        alertStyle = "error";
      }
      this.handleAlert(erMsg, actionObj, alertStyle, data.item[0]);
    } else if (data.item && data.item.length === 0) {
      this.removeExistingAlert();
    }
  }
  checkForFreTabChange(content) {
    if (content && content.freChange) {
      this.freChanged = true;
    } else if (content && content.freChange === false) {
      this.freChanged = false;
    }
  }

  setDetailsInSessionStorage(estimateDetails) {
    Object.keys(estimateDetails).forEach((item) => {
      this.setItem(item, estimateDetails[item]);
    });
  }

  setItem(params, data) {
    if (sessionStorage.getItem(params)) {
      sessionStorage.removeItem(params);
    }
    sessionStorage.setItem(params, data);
  }

  setDefaultLang() {
    if (localStorage.getItem("locale") === null) {
      localStorage.setItem("locale", "en");
    }
  }
}
