import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@app/services/misc/user.service';
import { OrgRole, SysRole } from '@app/services/models/roles';
import { DataService } from '@app/services/shared/storage/data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private dataService: DataService, private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.dataService.systemRole) {
      return this.securedRoute(route);
    }
    return this.userService.isProfileLoaded.pipe(
      map(res => {
        return this.securedRoute(route);
      })
    );
  }

  securedRoute(route: ActivatedRouteSnapshot) {
    if (this.dataService.canAccessCalc) {
      if (route.data.role && route.data.role === SysRole.Admin) {  // allow Admin page only if user is admin
        if (this.dataService.isUserAdmin) {
          return true;
        } else {
          return false;
        }
      } else if (route.data.component === 'global-settings' || route.data.component === 'price-upload') {
        if (this.dataService.systemRole === SysRole.Admin) {
          return true;
        } else {
          return false;
        }
      }
      if((route.routeConfig.path === 'settings' || route.routeConfig.path === 'admin' ) && this.dataService.orgRole === OrgRole.Intermediate) {
        return false;
      }
      return true;    // Allow advanced users alone to Advanced Calc pages
    }
    return false;
  }
}
